.ordersPage .cartItemControlsClose {
    width: auto;
}
.ordersPage .cartItem {
    padding-bottom: 25px;
}
.ordersPage .cartItemDescHeader {
    font-size: 42px !important;
}
@media (min-width:526px) {
    .ordersPage .cartItemControlsClose {
        top: 40%;
    }
}