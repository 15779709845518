.rowSection .homeTileDivider {
    margin-top: 30px;
    margin-bottom: 40px;
}
.rowSectionTitle {
    padding: 0;
    margin-left: 0 !important;
    overflow: visible;
    white-space: nowrap;
    float: left;
}
.sectionIconRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    transform: translateX(-100%);
    height: 46px;
}
.termText, .termHeader {
    font-weight: 300;
    line-height: 36px;
    font-family: myriad-pro, sans-serif;
    font-size: 18px;
    text-align: justify;
}
.termHeader {
    color: #ED1845;
}
.termList li {
    list-style: none;
    position: relative;
}
.termList li::before {
    font-size: 16px;
    content: '○';
    position: absolute;
    left: -16px;
}
@media (max-width: 993px) {
    .rowSectionTitle {
        margin: 0 auto !important;
        flex: 0 0 80%;
    }
}