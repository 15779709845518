.cartPage {
    min-height: calc(100vh - 96px);
}
.cartPage .tileSectionImage {
    height: 34px;
    margin-top: 7px;
}
.cartPage .homeTileDivider:nth-child(1) {
    display: none;
}
.cartItems {
    width: 100%;
}
.cartItem {
    min-height: 165px;
    overflow: auto;
}
.cartItemImageWrapper {
    width: 165px;
    min-height: 165px;
    float: left;
    display:flex;
    justify-content:center;
    align-items:center;
}
.cartItemImage {
    width: 165px;
    height: 165px;
    border-radius: 10px;
    box-shadow: 4px 4px 0px rgba(0,0,0,0.15);
    object-fit: cover;
}
.cartItemDescWrapper {
    position: relative;
    float: left;
    min-height: 165px;
    width: calc(100% - 360px);
    margin-left: 30px;
}
.cartItemDescText, .cartItemDescCode {
    color: #A0A0A0;
    text-transform: uppercase;
    font-size: 16px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.cartItemDescHeader {
    min-height: 86px;
    color: #262626;
    font-size: 60px !important;
    line-height: 86px !important;
    font-family: ff-prater-script-web, sans-serif !important;
    font-weight: 400 !important;
    text-transform: lowercase;
}
.cartItemDescHeader:link {
    text-decoration: none;
}
.cartItemDescText {
    min-height: 41px;
    margin-bottom: 10px;
}
.cartItemDescCode {
    min-height: 24px;
    margin-bottom: 0;
}
.cartItemControlsWrapper {
    position: relative;
    float: left;
    height: 165px;
    width: 165px;

}
.cartItemControlsClose {
    width: 20px;
    height: 24px;
    float: right;
    position: absolute;
    right: 0;
    top: 50%;
}
.cartItemControlsPrice {
    position: absolute;
    text-align: right;
    font-size: 36px;
    font-family: filson-soft, sans-serif;
    bottom: 0;
    right: 0;
}
.cartItemImage.noShadow {
    box-shadow: none;
}
.cartCrumbs {
    margin-top: 20px;
    width: 100%;
    height: 35px;
    min-width: 215px;
}
.cartCrumb {
    float: left;
}
.cartCrumb::after {
    content: "→";
    float: right;
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.cartCrumb.noAfter::after {
    content: none;
    display: none;
}
.cartCrumb:nth-child(2) img {
    filter: grayscale(1);
}
.cartCrumb img {
    width: 19px;
    height: 17px;
    margin-right: 5px;
    margin-top: -2px;
}
.profilePage .cartCrumb::after {
    content: "-";
    text-align: center;
}
.icon-red {
    filter: invert(22%) sepia(62%) saturate(7187%) hue-rotate(339deg) brightness(95%) contrast(96%) !important;
}
.icon-black {
    filter: brightness(0) !important;
}
.cartPriceSummary .cartItemControlsWrapper .cartItemControlsPrice {
    font-weight: 800;
}
.cartSaleText {
    font-size: 16px;
    text-transform: unset;
}
@media (max-width: 1080px) {
    .cartCrumbs {
        width: 415px;
        height: 100px;
    }
    .cartCrumb {
        margin-top: 20px;
    }
    .cartPage .collapsable {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
@media (max-width: 993px) {
    .rowSectionTitle {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .cartPage .homeTileVerticalDivider.visibility-none {
        display: none;
    }
    .cartPage .registerFormButtonContinue {
        margin-right: 1.5rem !important;
    }
    .cartPage .registerFormButtonSave, .cartPage .registerFormButtonContinue {
        width: unset;
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    .cartPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
    .cartCrumbs {
        width: 415px;
        height: 100px;
    }
}
@media (max-width: 786px) {
    .cartItemMobileImageWrapper {
        display: block;
    }
    .cartItemImageWrapper {
        display: none;
    }
    .cartPage {
        flex: 0 0 90% !important;
        max-width: 90% !important;
    }
    .cartPriceSummary .cartItemImageWrapper {
        display: block;
    }
    .cartPriceSummary {
        min-height: 165px;
    }
    .cartPriceSummary .cartItemImageWrapper img {
        width: 250px;
        height: 250px;
    }
    .cartPriceSummary .cartItemImageWrapper img {
        width: 165px;
        height: 165px;
        margin: 0 auto;
        display: block;
    }
    .orderPageButtonWrapper {
        flex-direction: column;
    }
}
@media (min-width: 526px) and (max-width: 786px) {
    .cartCrumbs {
        width: 208px;
        height: 200px;
    }
    .cartItem {
        position: relative;
    }
    .cartItemDescWrapper {
        width: 250px;
        margin: 0;
    }
    .cartItemControlsWrapper {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .cartItemMobileImageWrapper {
        width: 100%;
        padding-top: 100%;
        position: relative;
        margin-top: 10px;
    }
    .cartItemImage {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .cartItemDescHeader {
        text-align: center;
    }
    .cartItemDescText {
        text-align: center;
    }
    .cartItemDescCode {
        text-align: center;
    }
    .cartPriceSummary .cartItemDescWrapper {
        display: none;
    }
    .cartPage .registerFormButtonSave, .cartPage .registerFormButtonContinue {
        width: 327px;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
@media (max-width: 525px) {
    .cartPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
    .cartItem {
        width: 350px;
        margin: 0 auto;
    }
    .cartItemImage {
        width: 350px;
        height: 350px;
    }
    .cartItemDescWrapper {
        float: none;
        margin-left: 0;
        width: 100%;
    }
    .cartItemMobileImageWrapper {
        display: block;
        margin-top: 20px;
    }
    .cartItemImageWrapper {
        display: none;
    }
    .cartItemDescHeader {
        text-align: center;
    }
    .cartItemDescText {
        text-align: center;
    }
    .cartItemDescCode {
        text-align: center;
    }
    .cartItemControlsWrapper {
        width: 100%;
        height: 100px;
    }
    .cartItemControlsWrapper > * {
        right: 50%;
        transform: translateX(50%);
    }
    .cartItemControlsClose {
        top: 20%;
    }
    .cartItemControlsPrice {
        width: 100%;
        text-align: center;
    }
    .cartCrumbs {
        width: 45%;
        height: 200px;
    }
    .cartItem {
        width: 225px;
        margin: 0 auto;
    }
    .cartItemImage {
        width: 225px;
        height: 225px;
    }
    .cartItemDescHeader {
        font-size: 45px !important;
    }
    .cartPriceSummary {
        min-height: 270px;
    }
    .cartPriceSummary .cartItemImageWrapper {
        width: 225px;
    }
    .cartPage .registerFormButtonSave, .cartPage .registerFormButtonContinue {
        width: 100%;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
@media (min-width: 787px) {
    .cartItemMobileImageWrapper {
        display: none;
    }
    .cartItemImageWrapper {
        display: block;
    }
    .cartPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
    .cartSaleText {
        font-size: 24px;
    }
}
@media (min-width: 1200px) {
    .cartPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
}