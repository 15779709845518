.UtilsNavbarSpacer {
    width: 100%;
    height: 90px;
}
#LoadingOverlayElement {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d1d1d1;
    opacity: 75%;
    transition: 1s;
    z-index: 2000;
}
#LoadingOverlayElement > img {
    width: 10%;
    height: 10%;
    min-width: 50px;
    min-height: 50px;
    position: fixed;
    left: 45%;
    top: 45%;
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}