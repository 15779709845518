.TripleTile {
    margin-top: 65px !important;
    position: relative;
    padding: 0;
    max-width: 305px;
    width: 305px
}
.TripleTileInner {
    max-width: 305px;
    width: 305px;
    position: relative;
    border-radius: 10px;
    color: white;
}
.TripleTileInner .position-relative:hover a .CDPlayIcon {
    transform: rotate(360deg) !important;
    transition: transform 0.8s;
}
.TripleTileInner h3 {
    font-size: 76px;
    font-weight: 400;
    font-family: ff-prater-script-web, lobster, sans-serif;
    color: #262626;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
.TripleTileInner h4, .TripleTileInner h5 {
    font-size: 22px;
    font-weight: 300;
    font-family: myriad-pro, sans-serif;
    color: #A0A0A0;
    text-align: center;
}
@media (max-width: 896px) {
    .TripleTile .CDTilePriceEffect {
        display: block;
    }
}
@media (max-width: 850px) {
    .TripleTile {
        max-width: 330px;
        width: 330px
    }
    .TripleTileInner {
        max-width: 330px;
        width: 330px;
    }
}