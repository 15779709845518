.squirrelWrapper {
    margin-top: 100px;
    margin-bottom: 60px;
}
.miniPage .squirrelWrapper {
    margin-top: 40px;
}
.squirrelImage {
    width: 100%;
    height: 124px;
}
.squirrelText h4 {
    color: #ED1845;
    line-height: 48px;
    font-size: 40px;
    font-weight: 400;
    font-family: ff-prater-script-web, lobster, sans-serif;
    margin-top: 50px;
    word-spacing: 10px;
    text-align: center;
}
.squirrelText p {
    color: #A0A0A0;
    font-family: myriad-pro, sans-serif;
    font-size: 16px;
    line-height: 32px;
    margin-top: 40px;
    font-weight: 300;
    word-spacing: 4px;
}
.squirrelFormSubmit {
    border: none;
    width: 100%;
    height: 44px;
    background: none;
    padding: 0;
    cursor: unset !important;
    margin-top: 30px;
}
.squirrelFormSubmit img {
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 40px;
    transition: transform 0.6s;
}
.squirrelFormSubmit img:hover {
    transform: rotate(360deg);
    transition: transform 0.6s;
}
.squirrelForm {
    width: 245px;
}
.squirrelForm .inputWrongInfo {
    width: 100% !important;
    max-width: 100% !important;
}
.BlackSection .squirrelForm .inputWrongInfo > p {
    color: #ED1845;
    z-index: 1;
}
@media (max-width: 992px) {
    .squirrelText {
        width: 255px;
    }
    .squirrelMobileForm {
        width: 255px;
    }
    .squirrelList {
        width: 83%;
    }
}
@media (max-width: 325px) {
    .squirrelText {
        width: 95% !important;
    }
}