@media (max-width: 640px) {
    
}
@media (min-width: 640px) {
    
}
@media (min-width: 787px) {

}
@media (min-width: 897px) {
    .HomePageSection {
        margin-left: 55px;
        margin-right: 55px;
    }
}
@media (min-width: 1060px) {
    .HomePageSection {
        margin: 0 auto;
        max-width: 80vw;
    }
}
@media (min-width: 1160px) {
    .HomePageSection {
        max-width: 65vw;
    }
}