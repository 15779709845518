.tileSection {
    margin-top: 35px;
    max-width: 1070px;
}
.homeTileDivider {
    border-bottom: 1px solid #A0A0A0;
    margin-top: 65px;
}
.homeTileDivider.fat {
    border-bottom: 3px solid #A0A0A0;
}
.homeTileVerticalDivider {
    border-left: 1px solid #A0A0A0;
    margin: 0 5px;
    margin-top: 65px !important;
    display: block;
}
.tileSectionTitleWrapper {
    max-width: 1070px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.tileSectionTitle {
    padding: 0;
    margin: 0 !important;
    overflow: visible;
    white-space: nowrap;
    float: left;
}
.max-width-350 {
    max-width: 350px;
}
.tileSection h2 {
    font-size: 35px;
    line-height: 42px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.hidden {
    transform: scale(0) rotate(360deg);
}
.revealImage {
    transition: transform 0.6s;
}
.tileSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
    width: 46px;
    height: auto;
    max-height: 38px;
    margin-left: -55px;
}
.tileCloseButton {
    width: 20px !important;
    height: 20px !important;
    margin: auto;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
}
@media (min-width: 641px) and (max-width: 992px) {
    .collapseButton {
        position: absolute;
        top: 0;
        right: 0;
    }
    .tileCloseButton {
        padding: 0;
        right: 0;
    }
    .closeButtonWrapper {
        position: relative;
        height: 100%;
    }
}
.collapsable {
    height: 100%;
    transition: 0.6s;
}
.collapsed {
    transition: 0.6s;
    height: 0;
    overflow: hidden;
}
@media (min-width: 993px) {
    .tileRow {
        justify-content: space-between;
    }
    .tileRow .newsTile:nth-child(odd), .tileSection .tileSectionTitle:nth-child(1) {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
    .tileRow .newsTile:nth-child(even), .tileSection .tileSectionTitle:nth-child(3) {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}
@media (min-width: 641px) and (max-width: 786px) {
    .tileSectionWidth:nth-child(odd) {
        margin-left: 54px !important;
    }
    .tileSectionWidth:nth-child(even) {
        margin-right: 20px;
        margin-left: auto;
    }
    .tileSectionWidth:nth-child(even) > div {
        width: 50px;
    }
}
@media (max-width: 896px) {
    .tileSection {
        max-width: 100vw;
    }
    .tileSection h2 {
        line-height: 26px;
        font-size: 28px;
    }
    .tileSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
        width: 24px;
        margin-left: -30px;
        margin-top: 4px;
    }
    .homeTileDivider {
        flex: 0 0 86%;
        max-width: 100vw;
        margin: 0 auto;
    }
}
@media (max-width: 640px) {
    .tileSectionTitleWrapper {
        width: 281px;
    }
    .homeTileVerticalDivider {
        display: none;
    }
    .homeTileDivider {
        flex: 0 0 95%;
        max-width: 100vw;
        margin: 0 auto;
        margin-top: 50px;
    }
}
@media (min-width: 640px) {
    .homeTileDivider {
        flex: 0 0 96%;
        max-width: 100vw;
        margin: 0 auto;
        margin-top: 50px;
    }
}
@media (min-width: 787px) {
    .homeTileDivider {
        flex: 0 0 92%;
    }
}
@media (max-width: 896px) {
    .tileSectionWidth > div {
        width: 281px;
    }
}
@media (max-width: 639px) {
    .tileSection h2 {
        font-size: 22px;
    }
}
@media (min-width: 787px) and (max-width: 992px) {
    .tileSectionWidth {
        width: 281px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (min-width: 897px) {
    .homeTileDivider {
        flex: 0 0 100%;
    }
    .tileSectionWidth > div {
        width: 350px;
    }
}
@media (min-width: 1060px) {
    
}
@media (min-width: 1160px) {
    
}