.contactPage {
    min-height: 85vh;
}
.contactInfo p, .contactInfo h4 {
    margin-bottom: 0;
}
@media (max-width: 993px) {
    .contactInfo p, .contactInfo h4 {
        font-size: 18px;
        line-height: 28px;
    }
    .termText.color-red {
        white-space: nowrap;
    }
}
@media (max-width: 550px) {
    .contactInfo p, .contactInfo h4 {
        font-size: 14px;
    }   
}