h1 {
    font-family: grafolita-script, 'Dancing Script', sans-serif;
}
html {
    scroll-behavior: smooth;
}
.splashPage {
    position: relative;
}
.splashVideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
    filter: contrast(1.1) brightness(0.9);

}
.splashVideoOverlay {
    background-image: radial-gradient(#000000 50%, rgba(0,0,0,0) 5%);
    background-position: 0 0;
    background-size: 3px 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
.splashWrapper {
    margin: initial -20px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 280px;
    width: 100%;
}
.splashWrapper > h1 {
    color: white;
    max-width: 210px;
    float: left;
}
.splashSearch {
    margin-top: 12px;
}
.splashFromButtons {
    margin-top: 10px;
}
.splashFromButtons > div {
    width: 48px;
    height: 48px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.splashFromButtons > div img {
    width: 48px;
    height: 48px;
    color: white;
    transition: transform 0.8s;
}
.splashFromButtons > div img:hover {
    transform: rotate(360deg);
    transition: transform 0.8s;
}
.splashVideoMobile { 
    object-fit: cover;
    object-position: bottom;
    width: 100%;
    height: 100vh;
}
.splashWrapperMobile {
    max-width: 280px;
    width: 80%;
    top: 50%;
}
.spashArrow {
    position: absolute;
    bottom: 5%;
    left: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
    -webkit-animation: breathing 3s ease-out infinite normal;
    animation: breathing 3s ease-out infinite normal;
}
.spashArrow > img {
    width: 100%;
    height: 100%;
}
@supports (-moz-appearance:none) {
    .spashArrow {
        bottom: 13%;
    }
}
@keyframes breathing {
    0% {
      -webkit-transform: scale(0.9) translateX(-55%);
      -ms-transform: scale(0.9) translateX(-55%);
      transform: scale(0.9) translateX(-55%);
    }
  
    50% {
      -webkit-transform: scale(1.1) translateX(-45%);
      -ms-transform: scale(1.1) translateX(-45%);
      transform: scale(1.1) translateX(-45%);
    }
  
    100% {
      -webkit-transform: scale(0.9) translateX(-55%);
      -ms-transform: scale(0.9) translateX(-55%);
      transform: scale(0.9) translateX(-55%);
    }
  
    /*100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }*/
  }