.profilePage .rowSectionTitle img.tileSectionImage {
    width: 49px;
    height: 34px;
    margin-top: 7px;
}
.profilePage .registerForm {
    justify-content: space-between;
}
.profilePage .cartItemControlsPrice {
    white-space: nowrap;
}
.profilePage .cartItem {
    padding-bottom: 4px;
    position: relative;
}
.profilePage .cartItemControlsWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
}
@media (max-width: 1150px) {
    .profilePage .cartCrumbs.Profile {
        max-width: 505px;
        height: 110px;
    }
    .profilePage .cartCrumb {
        margin-top: 20px;
    }
}
@media (max-width: 896px) {
    .profilePage .rowSectionTitle img.tileSectionImage {
        margin-top: 0;
    }
}
@media (min-width: 787px) {
    .registerFormButtonSave {
        margin-right: 20px;
    }
}
@media (max-width: 786px) {
    .profilePage .cartCrumbs.Profile {
        max-width: 250px;
        height: 200px;
    }
}
@media (max-width: 525px) {
    .cartItem {
        width: 230px;
        margin: 0 auto;
    }
    .profilePage .cartItemDescWrapper {
        padding-bottom: 80px;
    }
}
@media (max-width: 400px) {
    .profilePage .tileSection h2 {
        white-space: pre-line;
        width: 200px;
    }
}