@media (min-width: 1251px) and (max-width: 1700px) {
    .SearchPage {
        max-width: 90% !important;
        flex: 0 0 90% !important;
    }
}
@media (min-width: 800px) and (max-width: 1250px) {
    .SearchPage {
        max-width: 80% !important;
        flex: 0 0 80% !important;
    }
}
.RedButton {
    border-radius: 10px;
    border: 1px solid #ED1845;
    background-color: #ED1845;
    color: white;
    text-align: center;
    height: 57px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.SearchPage .RedButton {
    width: 145px;
}
.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}