@keyframes popupCartWrapperAnim {
    0% {
        opacity: 1;
    }
    99.999% {
        top: 0 !important;
        visibility: visible;
    }
    100% {
        top: 100% !important;
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes popupCartConfirmFormShake {
    0% {
        margin-left: 0;
        margin-right: 0;
    }
    20% {
        margin-left: -15px;
        margin-right: 15px;
    }
    40% {
        margin-left: 15px;
        margin-right: -15px;
    }
    60% {
        margin-left: -15px;
        margin-right: 15px;
    }
    80% {
        margin-left: 15px;
        margin-right: -15px;
    }
    100% {
        margin-left: 0;
        margin-right: 0;
    }
}
#popupCartWrapper {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1032;
    transition: 0.8s;
}
#popupCart {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%) scale(0.3);
    height: 94%;
    border-radius: 15px;
    background-color: #232323;
    border: 1px solid #333333;
    padding: 50px;
    width: 580px;
    max-width: 580px;
    max-height: 680px;
    transition: 0.8s;
}
.cartPopedUp {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.cartPopedDown {
    animation: popupCartWrapperAnim 0.8s forwards;
}
.cartPopedUp #popupCart {
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
}

.popupCartClose {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    opacity: 0.5;
}
.popupCartPriceSumarry {
    margin: 30px 0 120px 0;
}
.popupCartPriceSumarry p:nth-child(1) {
    color: #5E5E5E !important;
    border-right: 1px solid #333333;
    font-size: 17px;
    font-family: alwyn-new-rounded-web, sans-serif;
    float: left;
    margin-left: 30px;
    margin-right: 20px;
    padding-right: 20px;
}
.popupCartPriceSumarry p:nth-child(2) {
    color: #ED1845 !important;
    font-size: 25px;
    font-family: filson-soft, sans-serif;
    float: left;
    margin-top: -7px;
    font-weight: 200;
}
.popupCartItemsSummary {
    border: 1px solid rgba(51, 51, 51, 0.71);;
    height: 60%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
}
/* width */
.popupCartItemsSummary::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.popupCartItemsSummary::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.popupCartItemsSummary::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.popupCartItemsSummary::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.popupCartItem {
    margin: 20px 0;
}
.popupCartItem:not(:last-child) {
    margin-bottom: 80px;
}
.popupCartItemLineOne {
    border-bottom: 1px solid #ED1845;
    width: calc(100% - 40px);
    padding-bottom: 7px;
    margin: 0 20px;
    color: white !important;
    font-size: 16px;
    font-family: filson-soft, sans-serif;
}
.popupCartItemLineOne > a {
    color: white !important;
    width: 100%;
    display: flex;
}
.popupCartItemLineOne > a:hover {
    color: white !important;
    text-decoration: none;
}
.popupCartItemLineTwo {
    width: calc(100% - 40px);
    padding: 7px 0;
    margin: 0 20px;
    color: #ED1845 !important;
    font-size: 16px;
    font-family: filson-soft, sans-serif;
}
.popupCartItemLineTwo > p {
    float: left;
}
.popupCartItemLineTwo input {
    border-radius: 100px;
    background-color: black;
    border: 1px solid rgb(168, 168, 168);
    width: 40px;
    height: 30px;
    color: white !important;
    text-align: center;
    font-size: 12px;
}
.popupCartItemLineTwo button {
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0 0 0 10px;
    background-color: rgb(237, 24, 69);
    border: none;
    border-radius: 100px;
    filter: brightness(0.6);
}
.popupCartItemLineTwo button:hover {
    filter: brightness(1);
}
.popupCartConfirmForm {
    margin-top: 40px;
}
.popupCartConfirmFormText {
    width: 210px;
    float: left;
    padding-left: 10px;
    border-left: 1px solid #333333;
    margin-left: 10px;
}
.popupCartConfirmForm a {
    font-size: 17px;
    line-height: 20px;
    color: #aaa !important;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 200;
}
.controlCartEmptyText {
    font-size: 20px;
    line-height: 20px;
    color: #aaa !important;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 300;
    text-align: center;
    margin-top: 100px;
}
.popupCartConfirmForm a:hover {
    color: #aaa !important;
    text-decoration: none;
}
.popupCartConfirmForm .cartSubmitButton {
    background-color: rgba(237,24,69,0.75);
    border-radius: 30px;
    border: none;
    color: white !important;
    width: 165px;
    height: 42px;
    font-family: filson-soft, sans-serif;
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    float: right;
    line-height: 42px;
}
.uberCheckbox {
    margin-top: 10px;
    float: left;
    appearance: none !important;
    border-radius: 100px;
    background-color: #383838;
    width: 31px;
    height: 31px;
    position: relative;
}
.uberCheckbox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: transparent;
    transition: all 0.2s;
    border: 3px solid white;
}
.uberCheckbox:checked::before {
    border-color: #ED1845;
    -webkit-transform: rotate(-45deg) translate(-50%, -50%);
    -moz-transform: rotate(-45deg) translate(-50%, -50%);
    -ms-transform: rotate(-45deg) translate(-50%, -50%);
    -o-transform: rotate(-45deg) translate(-50%, -50%);
    transform: rotate(-45deg) translate(-50%, -50%);
    height: 56% !important;
    width: 113%;
    border-top-style: none;
    left: 60%;
    top: -23%;
    border-right-style: none;
    border-radius: 0;
}
.popupCartConfirmFormWrapperAnim {
    animation: popupCartConfirmFormShake 1s;
}
@media (max-width: 600px) {
    #popupCart {
        width: 365px;
    }
    .cartSubmitButton {
        margin: 0 auto;
        float: none !important;
        display: table;
    }
    .popupCartConfirmFormText {
        margin-bottom: 10px;
    }
}
@media (max-width: 375px) {
    #popupCart {
        top: 0 !important;
        transform: scale(1) !important;
        left: 0;
        height: 100vh;
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 90px;
    }
    .uberCheckbox {
        margin-left: 20px;
    }
}