.squirrelText ul {
    margin-top: 30px;
    list-style-type: none;
    color: #A0A0A0;
    padding-left: 16px;
    padding-inline-start: 16px;
    font-size: 17px;
    font-weight: 300;
}
.squirrelText ul li::before {
    content: "○";
    font-size: 10px;
    margin-left: -15px;
    margin-right: 7px;
}
.squirrelList {
    width: 320px;
}