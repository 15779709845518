.summaryPage .cartItemDescText img, .summaryPage .cartItemDescCode img {
    margin-top: -3px;
    margin-right: 3px;
}
.summaryPage .homeTileVerticalDivider {
    display: none;
}
.summaryPage .cartItem {
    padding-bottom: 5px;
}
.summaryPage .registerForm {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
}
.summaryPage .registerForm input{
    cursor: default;
    color: #808080 !important;
}
#summaryShowCart {
    cursor: pointer;
}
@media (max-width: 993px) {
    .summaryPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
    .summaryPage .registerFormButtonSave, .summaryPage .registerFormButtonContinue {
        width: unset;
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
}
@media (max-width: 930px) {
    .summaryPage .desktopSpacer {
        display: none;
    }
    .summaryPage .deliveryLabel, .summaryPage .deliveryTitle {
        max-width: calc(50% - 17px) !important;
        flex: 0 0 50% !important;
    }
    .desktopOnly {
        display: none;
    }
}
@media (min-width: 787px) {
    .registerFormButtonContinue {
        margin-right: 20px;
    }
}
@media (max-width: 786px) {
    .summaryPage {
        flex: 0 0 90% !important;
        max-width: 90% !important;
    }
}
@media (min-width: 526px) and (max-width: 786px) {
    .summaryPage .registerFormButtonSave, .summaryPage .registerFormButtonContinue {
        width: 327px;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
@media (min-width: 601px) {
    .min-only {
        display: none;
    }
}
@media (max-width: 600px) {
    .max-only {
        display: none;
    }
    .summaryPage .deliveryLabel, .summaryPage .deliveryTitle {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        width: 100%;
    }
}
@media (max-width: 525px) {
    .summaryPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
    .summaryPage .registerFormButtonSave, .summaryPage .registerFormButtonContinue {
        width: 100%;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}