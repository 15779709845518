#popupWrapper {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1032;
    transition: 0.8s;
}
#popup {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%) scale(0.3);
    /*height: 94%;*/
    border-radius: 15px;
    background-color: #232323;
    border: 1px solid #333333;
    padding: 50px;
    width: 90%;
    min-height: 250px;
    max-width: 580px;
    max-height: 680px;
    transition: 0.8s;
}
.cartPopedUp #popup {
    top: 50%;
    transform: translate(-50%, -50%) scale(1);
}
#popupConfirm {
    background-color: #ED1845;
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
    text-align: center;
    border-radius: 30px;
    border: none;
    color: white !important;
    width: 165px;
    height: 42px;
    font-family: filson-soft, sans-serif;
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    float: right;
    line-height: 42px;
    cursor: pointer;
}
#popupConfirm:hover {
    color: #aaa !important;
}
#popupText {
    color: white;
    text-align: center;
    font-size: 20px;
    padding-bottom: 50px;
}
@media (min-width: 640px) {
    
}