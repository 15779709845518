.sidebar {
    width: calc(100% + 730px);
    height: 100%;
    position: fixed;
    top: 0;
    margin-left: -365px;
    z-index: -10;
    transition: all 0.6s !important;
}
.leftOut {
    margin-left: 0;
    z-index: 10;
}
.rightOut {
    margin-left: -730px;
    z-index: 10;
}
.leftBar {
    position: absolute;
    background-color: rgb(237, 24, 69);
    top: 0;
    left: 0;
    width: 365px;
    height: 100%;
    transition: 0.6s;
}
.middleBar {
    position: absolute;
    top: 0;
    left: 365px;
    width: calc(100% - 730px);
    height: 100%;
    transition: 0.6s;
}
.leftOut .middleBar, .rightOut .middleBar {
    cursor: url("/assets/images/close.svg");
}
.rightBar {
    position: absolute;
    background-color: rgb(237, 24, 69);
    top: 0;
    right: 0;
    width: 365px;
    height: 100%;
    transition: 0.6s;
}
.ml-365 {
    margin-left: 365px !important;
    margin-right: -365px !important;
}
.mr-365 {
    margin-left: -365px !important;
    margin-right: 365px !important;
}
@media (max-width: 640px) {
    .ml-365 {
        margin-left: 100vw !important;
        margin-right: -100vw !important;
    }
    .mr-365 {
        margin-left: -100vw !important;
        margin-right: 100vw !important;
    }
    .mobile-sidebar {
        width: 100vw !important;
        right: -100vw !important;
    }
}
.sidebarWrapper {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 75%;
}
.sidebarWrapper p {
    text-align: center;
    color: white;
    width: 100%;
}
.sidebarWrapper input {
    margin-bottom: 20px;
    color: black;
    text-align: center;
}
.mobile-sidebar {
    position: fixed;
    top: 0;
    right: -365px;
    margin-left: 0 !important;
    transition: margin 0.6s;
    z-index: 1032;
    background-color: #ED1845;
    width: 365px;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex !important;
}
.mobile-menu-close-img {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 38px;
    padding: 0 !important;
    filter: brightness(40);
    z-index: 1035;
}
.mobile-menu {
    z-index: 1034;
    display: none;
}
.mobile-menu-block {
    display: block;
}
.mobile-menu > a > img {
    padding: 40px;
    padding-top: 10px;
    cursor: pointer;
}
.mobile-sidebar-out {
    margin-left: 0;
}
@media (max-height: 840px) {
    .mobile-menu-filter .squirrelFormSubmit {
        margin-top: 0;
    }
    .mobile-menu-filter .sidebarWrapper {
        top: 54%;
    }
}