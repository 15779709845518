.TripleSection {
    margin-top: 35px;
    max-width: 1317px;
}
.TripleSection h2 {
    font-size: 35px;
    line-height: 42px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.TripleSectionTitleWrapper {
    max-width: 1317px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.TripleSectionTitle {
    padding: 0;
    margin: 0 !important;
    overflow: visible;
    white-space: nowrap;
    float: left;
}
.TripleSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
    width: 46px;
    height: auto;
    margin-left: -55px;
}
.SpaceAround {
    justify-content: space-around;
}
.ThreeRow {
    display: flex !important;
    
    justify-content: space-around;
}
.TwoRow {
    display: none !important;
}
.OneRow {
    display: none !important;
}
@media (max-width: 1700px) {
    .TripleSection {
        justify-content: space-between;
    }
}
@media (min-width: 1251px) and (max-width: 1700px) {
    .TripleSection {
        min-width: 1100px;
        width: 1100px;
    }
}
@media (max-width: 1250px) {
    .TripleSection {
        justify-content: space-between;
    }
    .TripleSection .tileRow {
        justify-content: space-between;
    }
    .ThreeRow {
        display: none !important;
    }
    .TwoRow {
        display: flex !important;
    }
    .OneRow {
        display: none !important;
    }
    .TripleSection .homeTileDivider {
        flex: 0 0 100% !important;
        max-width: 100vw !important;
        margin: 0 auto !important;
        margin-top: 50px !important;
    }
}
@media (max-width: 896px) {
    .TripleSection {
        max-width: 88vw;
    }
    .TripleSection .homeTileDivider {
        flex: 0 0 86%;
        max-width: 100vw;
        margin: 0 auto;
    }
}
@media (min-width: 787px) and (max-width: 992px) {
    .TripleSectionTitleWrapper .tileSectionWidth {
        height: unset;
        flex: unset;
    }
}
@media (min-width: 897px){
    .TripleSectionTitleWrapper .tileSectionWidth > div {
        width: 100%;
    }
}
@media (min-width: 851px) {
    .TripleSectionTitle .noMobile {
        display: block;
    }
    .TripleSectionTitle .onlyMobile {
        display: none;
    }
}
@media (max-width: 850px) {
    .ThreeRow {
        display: none !important;
    }
    .TwoRow {
        display: none !important;
    }
    .OneRow {
        display: flex !important;
        justify-content: center;
        padding: 0 50px;
    }
    .TripleSection h2 {
        line-height: 26px;
        font-size: 28px;
    }
    .TripleSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
        width: 24px;
        margin-left: -30px;
        margin-top: 4px;
    }
    .TripleSectionTitleWrapper {
        justify-content: space-around;
    }
    .TripleSection .tileSectionWidth:nth-child(even) {
        display: none;
    }
    .TripleSection .tileSectionWidth:nth-child(odd) {
        margin-left: 25px !important;
    }
    .TripleSection .homeTileDivider {
        max-width: 250px !important;
    }
    .TripleSectionTitle .noMobile {
        display: none;
    }
    .TripleSectionTitle .onlyMobile {
        display: block;
    }
}