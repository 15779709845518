@import url(https://use.typekit.net/ywc8whz.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*@import url('https://use.typekit.net/fgo1mak.css');*/

*:focus {
    outline: none !important;
}
body > iframe {
    display: none !important;/*some random iframe element keeps popping up, maybe something from react-router or bundle.js or some other package idk*/
}
main iframe {
    display: block !important;
}
body {
    width: 100%;
    background-color: #E8E8E8 !important;
    z-index: -100;
    overflow-x: hidden;
}
h1, h2, h3, h4, h5, p {
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
}
nav {
    width: 100vw;
}
main {
    transition: margin 0.6s;
    margin: 0;
}
a {
    color: unset !important;
}
a:hover {
    text-decoration: none !important;
}
.basicInput {
    padding: 16px;
    border-radius: 4px;
    border: none;
    font: 400 13.3333px Arial;
    height: 45px;
}
.standardFont {
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.container-fluid {
    padding: 0 !important;
    width: 100%;
}
.hiddenButton {
    border: none;
    background-color: transparent;
}
.hiddenButton:focus {
    outline: none;
}
.redLink {
    color: #ED1845 !important;
}
.red {
    color: #ED1845 !important;
}
.redLink:hover {
    color: #ED1845 !important;
    text-decoration: underline;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.addToCartWrapper {
    width: 135px;
    height: 135px;
    border-radius: 100px;
    background-color: #ED1845;
    position: fixed;
    top: -200px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    transition: all 0.8s;
    opacity: 0.9;
    z-index: 1031;
    cursor: pointer;
}
.addToCartWrapperDown {
    top: 10%;
}
.addToCartWrapper img {
    width: 50px;
    height: auto;
    margin: 42px;
    margin-top: 46px;
}
.color-gray {
    color: #A0A0A0 !important;
}
.placeholder-black::-webkit-input-placeholder {
    color: #2F2F2F !important;
}
.placeholder-black:-ms-input-placeholder {
    color: #2F2F2F !important;
}
.placeholder-black::placeholder {
    color: #2F2F2F !important;
}
.color-black {
    color: #2F2F2F !important;
}
.color-red {
    color: #ED1845 !important;
}
.color-white {
    color: white !important;
}
.min-h-100 {
    min-height: 100vh;
}
.vertical-center {
    display: flex;
    align-items: center;
  }
@media (max-width: 993px) {
    .w-95 {
        width: 95vw;
        margin: 0 auto;
    }
    .max-h-100 {
        max-height: 100vh;
        height: 100vh;
    }
}
@media (max-width: 640px) {
    .mobile-block {
        display: block;
    }
    .mobile-flex {
        display: flex;
    }
}
@media (min-width: 640px) {
    .mobile-block, .mobile-flex {
        display: none;
    }
}
nav {
    height: 90px;
    background-color: black;
    transition: background-color 1s;
    transition: margin 0.6s;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
}
@media (min-width: 1117px) {
    nav {
        height: 90px;
    }
    .navbar-brand {
        width: 45px !important;
        height: 45px !important;
    }
    .desktop-navbar {
        display: flex;
    }
    .mobile-navbar {
        display: none;
    }
}
@media (max-width: 1117px) {
    .desktop-navbar {
        display: none;
    }
    .mobile-navbar {
        margin-top: 15px;
        display: flex;
    }
}
nav a {
    color: white;
    font-size: 14px;
    text-transform: uppercase;
}
.navbarBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 0px solid black;
    transition: 0.5s;
}
.navbarBG.extend {
    border-bottom: 90px solid black;
    transition: 0.5s;
}
.navbar-items {
    position: absolute;
    right: 24px;
}
.navbar-nav > .nav-item {
    margin-left: 20px;
    height: 40px;
}
#navbarItemCount {
    position: absolute;
    right: 5.1%;
    top: 45%;
    font-size: 6px;
    z-index: 1;
    cursor: pointer;
}
@media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    #navbarItemCount {
        right: 5.2%;
    }
}
@-moz-document url-prefix() {
    #navbarItemCount {
        right: 4.94%;
    }
}
#navbarItemCount:hover + .nav-item {
    -webkit-filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
            filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
}
.nav-item.nav-link.navbarLink.profile {
    display: flex;
    align-items: center;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    color: white !important;
    max-width: 120px;
    overflow: hidden;
    font-size: 13px;
}
.nav-item.nav-link.navbarLink.profile:hover {
    -webkit-filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
            filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
}
.navbar-nav > .navbar-svg {
    width: 65px;
    cursor: pointer;
}
.navbar-nav > .nav-item:hover {
    -webkit-filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
            filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
}
.navbar-nav > .nav-item > img {
    height: 12px;
}
.navbar-nav > .nav-item > svg {
    margin-right: 3px;
    color: white;
}
.navbar-nav > .nav-item:hover > svg {
    margin-right: 3px;
    color: #ED1845;
}
.navbar-nav > a:hover {
    color: #ED1845;
}
.navbar-brand {
    width: 45px;
    height: 45px;
    z-index: 10;
}
.navbar-brand > img {
    margin-top: -10px;
}
@media (min-width: 1117px) {
    .navbar-brand > img {
        margin-top: -2px;
    }
}
.mobile-navbar-images {
    position: absolute;
    right: 10px;
    margin-top: 2px;
}
.mobile-navbar-images > * {
    cursor: pointer;
}
.mobile-navbar-images img {
    width: 21px;
    height: 21px;
    margin-right: 12px;
}
.mobile-cart-count {
    display: inline;
    margin-right: 12px;
    position: relative;
}
.mobile-cart-count p {
    position: absolute;
    top: 82%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-size: 8px;
    text-align: center;
    line-height: 10px;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 300;
    color: white;
}
.mobile-cart-count img {
    margin: 0;
}
h1 {
    font-family: grafolita-script, 'Dancing Script', sans-serif;
}
html {
    scroll-behavior: smooth;
}
.splashPage {
    position: relative;
}
.splashVideo {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: bottom;
    -webkit-filter: contrast(1.1) brightness(0.9);
            filter: contrast(1.1) brightness(0.9);

}
.splashVideoOverlay {
    background-image: radial-gradient(#000000 50%, rgba(0,0,0,0) 5%);
    background-position: 0 0;
    background-size: 3px 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}
.splashWrapper {
    margin: 0 -20px;
    margin: initial -20px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 280px;
    width: 100%;
}
.splashWrapper > h1 {
    color: white;
    max-width: 210px;
    float: left;
}
.splashSearch {
    margin-top: 12px;
}
.splashFromButtons {
    margin-top: 10px;
}
.splashFromButtons > div {
    width: 48px;
    height: 48px;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.splashFromButtons > div img {
    width: 48px;
    height: 48px;
    color: white;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
}
.splashFromButtons > div img:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
}
.splashVideoMobile { 
    object-fit: cover;
    object-position: bottom;
    width: 100%;
    height: 100vh;
}
.splashWrapperMobile {
    max-width: 280px;
    width: 80%;
    top: 50%;
}
.spashArrow {
    position: absolute;
    bottom: 5%;
    left: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
    -webkit-animation: breathing 3s ease-out infinite normal;
    animation: breathing 3s ease-out infinite normal;
}
.spashArrow > img {
    width: 100%;
    height: 100%;
}
@supports (-moz-appearance:none) {
    .spashArrow {
        bottom: 13%;
    }
}
@-webkit-keyframes breathing {
    0% {
      -webkit-transform: scale(0.9) translateX(-55%);
      transform: scale(0.9) translateX(-55%);
    }
  
    50% {
      -webkit-transform: scale(1.1) translateX(-45%);
      transform: scale(1.1) translateX(-45%);
    }
  
    100% {
      -webkit-transform: scale(0.9) translateX(-55%);
      transform: scale(0.9) translateX(-55%);
    }
  
    /*100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }*/
  }
@keyframes breathing {
    0% {
      -webkit-transform: scale(0.9) translateX(-55%);
      transform: scale(0.9) translateX(-55%);
    }
  
    50% {
      -webkit-transform: scale(1.1) translateX(-45%);
      transform: scale(1.1) translateX(-45%);
    }
  
    100% {
      -webkit-transform: scale(0.9) translateX(-55%);
      transform: scale(0.9) translateX(-55%);
    }
  
    /*100% {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }*/
  }
.tileSection {
    margin-top: 35px;
    max-width: 1070px;
}
.homeTileDivider {
    border-bottom: 1px solid #A0A0A0;
    margin-top: 65px;
}
.homeTileDivider.fat {
    border-bottom: 3px solid #A0A0A0;
}
.homeTileVerticalDivider {
    border-left: 1px solid #A0A0A0;
    margin: 0 5px;
    margin-top: 65px !important;
    display: block;
}
.tileSectionTitleWrapper {
    max-width: 1070px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.tileSectionTitle {
    padding: 0;
    margin: 0 !important;
    overflow: visible;
    white-space: nowrap;
    float: left;
}
.max-width-350 {
    max-width: 350px;
}
.tileSection h2 {
    font-size: 35px;
    line-height: 42px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.hidden {
    -webkit-transform: scale(0) rotate(360deg);
            transform: scale(0) rotate(360deg);
}
.revealImage {
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
}
.tileSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
    width: 46px;
    height: auto;
    max-height: 38px;
    margin-left: -55px;
}
.tileCloseButton {
    width: 20px !important;
    height: 20px !important;
    margin: auto;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
}
@media (min-width: 641px) and (max-width: 992px) {
    .collapseButton {
        position: absolute;
        top: 0;
        right: 0;
    }
    .tileCloseButton {
        padding: 0;
        right: 0;
    }
    .closeButtonWrapper {
        position: relative;
        height: 100%;
    }
}
.collapsable {
    height: 100%;
    transition: 0.6s;
}
.collapsed {
    transition: 0.6s;
    height: 0;
    overflow: hidden;
}
@media (min-width: 993px) {
    .tileRow {
        justify-content: space-between;
    }
    .tileRow .newsTile:nth-child(odd), .tileSection .tileSectionTitle:nth-child(1) {
        margin-right: 10px !important;
        margin-left: 10px !important;
    }
    .tileRow .newsTile:nth-child(even), .tileSection .tileSectionTitle:nth-child(3) {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}
@media (min-width: 641px) and (max-width: 786px) {
    .tileSectionWidth:nth-child(odd) {
        margin-left: 54px !important;
    }
    .tileSectionWidth:nth-child(even) {
        margin-right: 20px;
        margin-left: auto;
    }
    .tileSectionWidth:nth-child(even) > div {
        width: 50px;
    }
}
@media (max-width: 896px) {
    .tileSection {
        max-width: 100vw;
    }
    .tileSection h2 {
        line-height: 26px;
        font-size: 28px;
    }
    .tileSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
        width: 24px;
        margin-left: -30px;
        margin-top: 4px;
    }
    .homeTileDivider {
        flex: 0 0 86%;
        max-width: 100vw;
        margin: 0 auto;
    }
}
@media (max-width: 640px) {
    .tileSectionTitleWrapper {
        width: 281px;
    }
    .homeTileVerticalDivider {
        display: none;
    }
    .homeTileDivider {
        flex: 0 0 95%;
        max-width: 100vw;
        margin: 0 auto;
        margin-top: 50px;
    }
}
@media (min-width: 640px) {
    .homeTileDivider {
        flex: 0 0 96%;
        max-width: 100vw;
        margin: 0 auto;
        margin-top: 50px;
    }
}
@media (min-width: 787px) {
    .homeTileDivider {
        flex: 0 0 92%;
    }
}
@media (max-width: 896px) {
    .tileSectionWidth > div {
        width: 281px;
    }
}
@media (max-width: 639px) {
    .tileSection h2 {
        font-size: 22px;
    }
}
@media (min-width: 787px) and (max-width: 992px) {
    .tileSectionWidth {
        width: 281px;
        flex: 1 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
@media (min-width: 897px) {
    .homeTileDivider {
        flex: 0 0 100%;
    }
    .tileSectionWidth > div {
        width: 350px;
    }
}
@media (min-width: 1060px) {
    
}
@media (min-width: 1160px) {
    
}
.TripleSection {
    margin-top: 35px;
    max-width: 1317px;
}
.TripleSection h2 {
    font-size: 35px;
    line-height: 42px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.TripleSectionTitleWrapper {
    max-width: 1317px;
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.TripleSectionTitle {
    padding: 0;
    margin: 0 !important;
    overflow: visible;
    white-space: nowrap;
    float: left;
}
.TripleSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
    width: 46px;
    height: auto;
    margin-left: -55px;
}
.SpaceAround {
    justify-content: space-around;
}
.ThreeRow {
    display: flex !important;
    
    justify-content: space-around;
}
.TwoRow {
    display: none !important;
}
.OneRow {
    display: none !important;
}
@media (max-width: 1700px) {
    .TripleSection {
        justify-content: space-between;
    }
}
@media (min-width: 1251px) and (max-width: 1700px) {
    .TripleSection {
        min-width: 1100px;
        width: 1100px;
    }
}
@media (max-width: 1250px) {
    .TripleSection {
        justify-content: space-between;
    }
    .TripleSection .tileRow {
        justify-content: space-between;
    }
    .ThreeRow {
        display: none !important;
    }
    .TwoRow {
        display: flex !important;
    }
    .OneRow {
        display: none !important;
    }
    .TripleSection .homeTileDivider {
        flex: 0 0 100% !important;
        max-width: 100vw !important;
        margin: 0 auto !important;
        margin-top: 50px !important;
    }
}
@media (max-width: 896px) {
    .TripleSection {
        max-width: 88vw;
    }
    .TripleSection .homeTileDivider {
        flex: 0 0 86%;
        max-width: 100vw;
        margin: 0 auto;
    }
}
@media (min-width: 787px) and (max-width: 992px) {
    .TripleSectionTitleWrapper .tileSectionWidth {
        height: unset;
        flex: unset;
    }
}
@media (min-width: 897px){
    .TripleSectionTitleWrapper .tileSectionWidth > div {
        width: 100%;
    }
}
@media (min-width: 851px) {
    .TripleSectionTitle .noMobile {
        display: block;
    }
    .TripleSectionTitle .onlyMobile {
        display: none;
    }
}
@media (max-width: 850px) {
    .ThreeRow {
        display: none !important;
    }
    .TwoRow {
        display: none !important;
    }
    .OneRow {
        display: flex !important;
        justify-content: center;
        padding: 0 50px;
    }
    .TripleSection h2 {
        line-height: 26px;
        font-size: 28px;
    }
    .TripleSectionTitle img.tileSectionImage, .rowSectionTitle img.tileSectionImage {
        width: 24px;
        margin-left: -30px;
        margin-top: 4px;
    }
    .TripleSectionTitleWrapper {
        justify-content: space-around;
    }
    .TripleSection .tileSectionWidth:nth-child(even) {
        display: none;
    }
    .TripleSection .tileSectionWidth:nth-child(odd) {
        margin-left: 25px !important;
    }
    .TripleSection .homeTileDivider {
        max-width: 250px !important;
    }
    .TripleSectionTitle .noMobile {
        display: none;
    }
    .TripleSectionTitle .onlyMobile {
        display: block;
    }
}
.newsTile {
    margin-top: 65px !important;
    position: relative;
    padding: 0;
    max-width: 350px;
    width: 350px;
}
.newsText {
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100px;
    background-color: #ED1845;
    border-radius: 10px;
    color: white;
    z-index: -10;
    padding-top: 8px;
    font-size: 15px;
    font-family: myriad-pro, sans-serif;
}
.popedUp {
    top: -35px;
    transition: 0.8s;
}
.popedDown {
    top: 0;
    transition: 0.8s;
}
.newsText > img {
    margin-right: 5px;
    margin-top: -5px;
    width: 30px;
    height: 30px;
}
.newsInner {
    max-width: 350px;
    position: relative;
    border-radius: 10px;
    color: white;
}
.newsImageWrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
}
.newsImage {
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 6px 6px 0px rgba(0,0,0,0.15);
    position: absolute;
    top: 0;
    left: 0;
}
.newsBooklet {
    position: absolute;
    top: 0;
    left: 0;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    border-radius: 175px;
}
.newsBooklet:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
}
.newsShare {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
}
.newsContent {
    border-radius: 250px;
    transition: 0.6s;
    padding: 50% 0;
}
.newsContent:hover {
    transition: 0.6s;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
.newsContentWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
@media (min-width: 641px) and (max-width: 786px) {
    .newsTile {
        max-width: 281px;
    }
    .tileRow .newsTile:nth-child(odd) {
        margin-right: auto !important;
        margin-left: 20px !important;
    }
    .tileRow .newsTile:nth-child(even) {
        margin-left: auto !important;
        margin-right: 20px !important;
    }
}

@-webkit-keyframes blink {
    100% {
        opacity: 0;
        width: 190%;
        height: 190%;
    }
    50% {
        opacity: 1;
    }
}
@keyframes blink {
    100% {
        opacity: 0;
        width: 190%;
        height: 190%;
    }
    50% {
        opacity: 1;
    }
}
.CDTilePriceEffect {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 1px solid white;
    border-radius: 1000px;
    z-index: -12;
    opacity: 1;
    -webkit-animation: blink 1.5s ease infinite;
            animation: blink 1.5s ease infinite;
}
.CDTilePrice {
    width: 124px;
    height: 124px;
    text-align: center;
    border-radius: 150px;
    background-color: #ED1845;
    border: 1px solid #ED1845;
    position: relative;
    margin: 75px auto;
    transition: 0.6s ease;
    cursor: pointer;
}
.CDTilePrice:hover {
    width: 150px;
    height: 150px;
    background-color: white;
    transition: 0.6s ease;
    margin: 62px auto;
    color: #ED1845;
}
.CDTilePriceInner {
    position: absolute;
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width:100%;
    height: 100%;
    line-height: 5;
    transition: all 0.6s ease;
    color: white;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-size: 17px;
    font-weight: 200;
}
.CDTilePrice:hover .CDTilePriceInner {
    top: -215px;
    transition: all 0.6s ease;
    color: #ED1845;
    font-size: 23px;
}
.CDTilePriceWrapper {
    margin-bottom: -60px;
}
.CDPlayIcon {
    width: 60px;
    height: 60px;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
}
/*.CDPlayIcon:hover {
    transform: rotate(360deg) !important;
    transition: transform 0.8s;
}*/
.CDTileInner .position-relative:hover a .CDPlayIcon {
    -webkit-transform: rotate(360deg) !important;
            transform: rotate(360deg) !important;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
}
.CDPlayIconWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.CDTileInner {
    max-width: 350px;
    width: 350px;
    position: relative;
    border-radius: 10px;
    color: white;
}
.CDTileInner h3 {
    font-size: 76px;
    font-weight: 400;
    font-family: ff-prater-script-web, lobster, sans-serif;
    color: #262626;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
.CDTileInner h4, .CDTileInner h5 {
    font-size: 22px;
    font-weight: 300;
    font-family: myriad-pro, sans-serif;
    color: #A0A0A0;
    text-align: center;
}
.CDTileInner h5 {
    margin-top: 20px;
}
.CDTileShare {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.CDTileShare > img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
    cursor: pointer;
}
.CDTileShare > .CDTileShareBody {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 200px;
    height: 120px;
    text-align: left;
    background-color: #ED1845;
    border-radius: 10px;
    visibility: hidden;
    z-index: 1;
}
.CDTileShare:hover > .CDTileShareBody {
    visibility: visible;
    animation: shareMenuRevealAnimation 0.15s reverse;
}
@-webkit-keyframes shareMenuRevealAnimation {
    0% {
        opacity: 1;
    }
    99.999% {
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes shareMenuRevealAnimation {
    0% {
        opacity: 1;
    }
    99.999% {
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.CDTileShare > .CDTileShareBody .CDTileShareItem {
    width: 100%;
    height: 50px;
    padding: 8px;
    align-self: stretch;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    cursor: pointer;
    line-height: 34px;
}
.CDTileShare > .CDTileShareBody .CDTileShareItem:hover {
    background-color: #b51335;
    transition: background-color 0.15s;
    transition-timing-function: linear;
}
.CDDiscIcon {
    position: absolute;
    right: 48px;
    bottom: 10px;
    width: 28px;
    height: 28px;
}
.commentPopupWapper {
    position: absolute;
    right: 86px;
    bottom: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid white;
    z-index: 1;
}
.commentPopupWapper::after {
    color: white;
    content: '?';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.commentPopupWapper .commentPopupInner {
    /*display: none;*/
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, 5px);
            transform: translate(-50%, 5px);
    padding: 10px;
    color: white;
    background: #ED1845;
    width: -webkit-max-content;
    width: max-content;
    max-width: 360px;
    /*min-height: 25px;
    aspect-ratio: 3/2;*/
    border-radius: 5px;
    font-size: 22px;
    font-weight: 300;
    font-family: myriad-pro, sans-serif;
    text-align: center;
    visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.6s, opacity 0.6s linear;
}
.commentPopupWapper:hover .commentPopupInner {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.6s linear;
}
@media (max-width: 896px) {
    .CDTileInner, .newsTile {
        max-width: 281px;
    }
    .CDTileInner > h3 {
        font-size: 56px !important;
    }
    .CDTilePriceEffect {
        display: none;
    }
}
@media (max-width: 320px) {
    .CDTileInner, .newsTile {
        max-width: 245px;
    }
    .tileSectionTitleWrapper {
        width: 245px;
    }
}
.TripleTile {
    margin-top: 65px !important;
    position: relative;
    padding: 0;
    max-width: 305px;
    width: 305px
}
.TripleTileInner {
    max-width: 305px;
    width: 305px;
    position: relative;
    border-radius: 10px;
    color: white;
}
.TripleTileInner .position-relative:hover a .CDPlayIcon {
    -webkit-transform: rotate(360deg) !important;
            transform: rotate(360deg) !important;
    transition: -webkit-transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
}
.TripleTileInner h3 {
    font-size: 76px;
    font-weight: 400;
    font-family: ff-prater-script-web, lobster, sans-serif;
    color: #262626;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
.TripleTileInner h4, .TripleTileInner h5 {
    font-size: 22px;
    font-weight: 300;
    font-family: myriad-pro, sans-serif;
    color: #A0A0A0;
    text-align: center;
}
@media (max-width: 896px) {
    .TripleTile .CDTilePriceEffect {
        display: block;
    }
}
@media (max-width: 850px) {
    .TripleTile {
        max-width: 330px;
        width: 330px
    }
    .TripleTileInner {
        max-width: 330px;
        width: 330px;
    }
}
.BlackSection {
    background-color: black;
    padding: 40px 0;
    margin: 0 auto;
}
.squirrelWrapper {
    margin-top: 100px;
    margin-bottom: 60px;
}
.miniPage .squirrelWrapper {
    margin-top: 40px;
}
.squirrelImage {
    width: 100%;
    height: 124px;
}
.squirrelText h4 {
    color: #ED1845;
    line-height: 48px;
    font-size: 40px;
    font-weight: 400;
    font-family: ff-prater-script-web, lobster, sans-serif;
    margin-top: 50px;
    word-spacing: 10px;
    text-align: center;
}
.squirrelText p {
    color: #A0A0A0;
    font-family: myriad-pro, sans-serif;
    font-size: 16px;
    line-height: 32px;
    margin-top: 40px;
    font-weight: 300;
    word-spacing: 4px;
}
.squirrelFormSubmit {
    border: none;
    width: 100%;
    height: 44px;
    background: none;
    padding: 0;
    cursor: unset !important;
    margin-top: 30px;
}
.squirrelFormSubmit img {
    width: 44px;
    height: 44px;
    cursor: pointer;
    border-radius: 40px;
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
}
.squirrelFormSubmit img:hover {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    transition: -webkit-transform 0.6s;
    transition: transform 0.6s;
    transition: transform 0.6s, -webkit-transform 0.6s;
}
.squirrelForm {
    width: 245px;
}
.squirrelForm .inputWrongInfo {
    width: 100% !important;
    max-width: 100% !important;
}
.BlackSection .squirrelForm .inputWrongInfo > p {
    color: #ED1845;
    z-index: 1;
}
@media (max-width: 992px) {
    .squirrelText {
        width: 255px;
    }
    .squirrelMobileForm {
        width: 255px;
    }
    .squirrelList {
        width: 83%;
    }
}
@media (max-width: 325px) {
    .squirrelText {
        width: 95% !important;
    }
}
.squirrelText ul {
    margin-top: 30px;
    list-style-type: none;
    color: #A0A0A0;
    padding-left: 16px;
    -webkit-padding-start: 16px;
            padding-inline-start: 16px;
    font-size: 17px;
    font-weight: 300;
}
.squirrelText ul li::before {
    content: "○";
    font-size: 10px;
    margin-left: -15px;
    margin-right: 7px;
}
.squirrelList {
    width: 320px;
}
.footer {
    background-color: black;
    color: white;
    height: 94px;
    white-space: pre;
    font-size: 14px;
    font-family: myriad-pro, sans-serif;
    padding: 36px !important;
    font-weight: 300;
}
.footer a {
    color: #ED1845 !important;
    font-weight: normal;
}
@media (max-width: 992px) {

    .footer * {
        font-size: 11px;
    }

}
@media (min-width: 992px) {

    .footer {
        padding-left: 80px !important;
    }

}
/* cursor: url('path-to-image.png'), auto;	 */
.ControlUtils {
    position: fixed;
    top: -100px;
    cursor: pointer;
}
.ControlImgLeft {
    position: fixed;
    left: 20px;
    margin-top: 20px;
}
.ControlImgLeft img {
    width: 30px;
    height: 33px;
}
.ControlImgRight {
    position: fixed;
    right: 20px;
}
.ControlImgRight img {
    width: 55px;
    height: 46px;
}
.ControlImgRight p {
    width: 100%;
    text-align: center;
    margin-top: -30px;
    color: white;
}
.ControlUtilsUp > .ControlUtils {
    transition: all 0.6s;
    top: -100px;
}
@media (min-width: 1117px) {
    .ControlUtilsDown > .ControlUtils {
        transition: all 0.6s;
        top: 95%;
        -webkit-transform: translateY(-40px);
                transform: translateY(-40px);
    }
}
.sidebar {
    width: calc(100% + 730px);
    height: 100%;
    position: fixed;
    top: 0;
    margin-left: -365px;
    z-index: -10;
    transition: all 0.6s !important;
}
.leftOut {
    margin-left: 0;
    z-index: 10;
}
.rightOut {
    margin-left: -730px;
    z-index: 10;
}
.leftBar {
    position: absolute;
    background-color: rgb(237, 24, 69);
    top: 0;
    left: 0;
    width: 365px;
    height: 100%;
    transition: 0.6s;
}
.middleBar {
    position: absolute;
    top: 0;
    left: 365px;
    width: calc(100% - 730px);
    height: 100%;
    transition: 0.6s;
}
.leftOut .middleBar, .rightOut .middleBar {
    cursor: url("/assets/images/close.svg");
}
.rightBar {
    position: absolute;
    background-color: rgb(237, 24, 69);
    top: 0;
    right: 0;
    width: 365px;
    height: 100%;
    transition: 0.6s;
}
.ml-365 {
    margin-left: 365px !important;
    margin-right: -365px !important;
}
.mr-365 {
    margin-left: -365px !important;
    margin-right: 365px !important;
}
@media (max-width: 640px) {
    .ml-365 {
        margin-left: 100vw !important;
        margin-right: -100vw !important;
    }
    .mr-365 {
        margin-left: -100vw !important;
        margin-right: 100vw !important;
    }
    .mobile-sidebar {
        width: 100vw !important;
        right: -100vw !important;
    }
}
.sidebarWrapper {
    position: absolute;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 75%;
}
.sidebarWrapper p {
    text-align: center;
    color: white;
    width: 100%;
}
.sidebarWrapper input {
    margin-bottom: 20px;
    color: black;
    text-align: center;
}
.mobile-sidebar {
    position: fixed;
    top: 0;
    right: -365px;
    margin-left: 0 !important;
    transition: margin 0.6s;
    z-index: 1032;
    background-color: #ED1845;
    width: 365px;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex !important;
}
.mobile-menu-close-img {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 38px;
    padding: 0 !important;
    -webkit-filter: brightness(40);
            filter: brightness(40);
    z-index: 1035;
}
.mobile-menu {
    z-index: 1034;
    display: none;
}
.mobile-menu-block {
    display: block;
}
.mobile-menu > a > img {
    padding: 40px;
    padding-top: 10px;
    cursor: pointer;
}
.mobile-sidebar-out {
    margin-left: 0;
}
@media (max-height: 840px) {
    .mobile-menu-filter .squirrelFormSubmit {
        margin-top: 0;
    }
    .mobile-menu-filter .sidebarWrapper {
        top: 54%;
    }
}
@-webkit-keyframes popupCartWrapperAnim {
    0% {
        opacity: 1;
    }
    99.999% {
        top: 0 !important;
        visibility: visible;
    }
    100% {
        top: 100% !important;
        opacity: 0;
        visibility: hidden;
    }
}
@keyframes popupCartWrapperAnim {
    0% {
        opacity: 1;
    }
    99.999% {
        top: 0 !important;
        visibility: visible;
    }
    100% {
        top: 100% !important;
        opacity: 0;
        visibility: hidden;
    }
}
@-webkit-keyframes popupCartConfirmFormShake {
    0% {
        margin-left: 0;
        margin-right: 0;
    }
    20% {
        margin-left: -15px;
        margin-right: 15px;
    }
    40% {
        margin-left: 15px;
        margin-right: -15px;
    }
    60% {
        margin-left: -15px;
        margin-right: 15px;
    }
    80% {
        margin-left: 15px;
        margin-right: -15px;
    }
    100% {
        margin-left: 0;
        margin-right: 0;
    }
}
@keyframes popupCartConfirmFormShake {
    0% {
        margin-left: 0;
        margin-right: 0;
    }
    20% {
        margin-left: -15px;
        margin-right: 15px;
    }
    40% {
        margin-left: 15px;
        margin-right: -15px;
    }
    60% {
        margin-left: -15px;
        margin-right: 15px;
    }
    80% {
        margin-left: 15px;
        margin-right: -15px;
    }
    100% {
        margin-left: 0;
        margin-right: 0;
    }
}
#popupCartWrapper {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1032;
    transition: 0.8s;
}
#popupCart {
    position: absolute;
    top: 105%;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(0.3);
            transform: translateX(-50%) scale(0.3);
    height: 94%;
    border-radius: 15px;
    background-color: #232323;
    border: 1px solid #333333;
    padding: 50px;
    width: 580px;
    max-width: 580px;
    max-height: 680px;
    transition: 0.8s;
}
.cartPopedUp {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.cartPopedDown {
    -webkit-animation: popupCartWrapperAnim 0.8s forwards;
            animation: popupCartWrapperAnim 0.8s forwards;
}
.cartPopedUp #popupCart {
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

.popupCartClose {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    opacity: 0.5;
}
.popupCartPriceSumarry {
    margin: 30px 0 120px 0;
}
.popupCartPriceSumarry p:nth-child(1) {
    color: #5E5E5E !important;
    border-right: 1px solid #333333;
    font-size: 17px;
    font-family: alwyn-new-rounded-web, sans-serif;
    float: left;
    margin-left: 30px;
    margin-right: 20px;
    padding-right: 20px;
}
.popupCartPriceSumarry p:nth-child(2) {
    color: #ED1845 !important;
    font-size: 25px;
    font-family: filson-soft, sans-serif;
    float: left;
    margin-top: -7px;
    font-weight: 200;
}
.popupCartItemsSummary {
    border: 1px solid rgba(51, 51, 51, 0.71);;
    height: 60%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
}
/* width */
.popupCartItemsSummary::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.popupCartItemsSummary::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.popupCartItemsSummary::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.popupCartItemsSummary::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.popupCartItem {
    margin: 20px 0;
}
.popupCartItem:not(:last-child) {
    margin-bottom: 80px;
}
.popupCartItemLineOne {
    border-bottom: 1px solid #ED1845;
    width: calc(100% - 40px);
    padding-bottom: 7px;
    margin: 0 20px;
    color: white !important;
    font-size: 16px;
    font-family: filson-soft, sans-serif;
}
.popupCartItemLineOne > a {
    color: white !important;
    width: 100%;
    display: flex;
}
.popupCartItemLineOne > a:hover {
    color: white !important;
    text-decoration: none;
}
.popupCartItemLineTwo {
    width: calc(100% - 40px);
    padding: 7px 0;
    margin: 0 20px;
    color: #ED1845 !important;
    font-size: 16px;
    font-family: filson-soft, sans-serif;
}
.popupCartItemLineTwo > p {
    float: left;
}
.popupCartItemLineTwo input {
    border-radius: 100px;
    background-color: black;
    border: 1px solid rgb(168, 168, 168);
    width: 40px;
    height: 30px;
    color: white !important;
    text-align: center;
    font-size: 12px;
}
.popupCartItemLineTwo button {
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0 0 0 10px;
    background-color: rgb(237, 24, 69);
    border: none;
    border-radius: 100px;
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
}
.popupCartItemLineTwo button:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
}
.popupCartConfirmForm {
    margin-top: 40px;
}
.popupCartConfirmFormText {
    width: 210px;
    float: left;
    padding-left: 10px;
    border-left: 1px solid #333333;
    margin-left: 10px;
}
.popupCartConfirmForm a {
    font-size: 17px;
    line-height: 20px;
    color: #aaa !important;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 200;
}
.controlCartEmptyText {
    font-size: 20px;
    line-height: 20px;
    color: #aaa !important;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 300;
    text-align: center;
    margin-top: 100px;
}
.popupCartConfirmForm a:hover {
    color: #aaa !important;
    text-decoration: none;
}
.popupCartConfirmForm .cartSubmitButton {
    background-color: rgba(237,24,69,0.75);
    border-radius: 30px;
    border: none;
    color: white !important;
    width: 165px;
    height: 42px;
    font-family: filson-soft, sans-serif;
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    float: right;
    line-height: 42px;
}
.uberCheckbox {
    margin-top: 10px;
    float: left;
    -webkit-appearance: none !important;
            appearance: none !important;
    border-radius: 100px;
    background-color: #383838;
    width: 31px;
    height: 31px;
    position: relative;
}
.uberCheckbox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: transparent;
    transition: all 0.2s;
    border: 3px solid white;
}
.uberCheckbox:checked::before {
    border-color: #ED1845;
    -webkit-transform: rotate(-45deg) translate(-50%, -50%);
    transform: rotate(-45deg) translate(-50%, -50%);
    height: 56% !important;
    width: 113%;
    border-top-style: none;
    left: 60%;
    top: -23%;
    border-right-style: none;
    border-radius: 0;
}
.popupCartConfirmFormWrapperAnim {
    -webkit-animation: popupCartConfirmFormShake 1s;
            animation: popupCartConfirmFormShake 1s;
}
@media (max-width: 600px) {
    #popupCart {
        width: 365px;
    }
    .cartSubmitButton {
        margin: 0 auto;
        float: none !important;
        display: table;
    }
    .popupCartConfirmFormText {
        margin-bottom: 10px;
    }
}
@media (max-width: 375px) {
    #popupCart {
        top: 0 !important;
        -webkit-transform: scale(1) !important;
                transform: scale(1) !important;
        left: 0;
        height: 100vh;
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 90px;
    }
    .uberCheckbox {
        margin-left: 20px;
    }
}
#displayCartWrapper {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1032;
    transition: 0.8s;
}
#displayCart {
    position: absolute;
    top: 105%;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(0.3);
            transform: translateX(-50%) scale(0.3);
    height: 94%;
    border-radius: 15px;
    background-color: #232323;
    border: 1px solid #333333;
    padding: 50px;
    width: 580px;
    max-width: 580px;
    max-height: 680px;
    transition: 0.8s;
}
.cartPopedUp {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
.cartPopedDown {
    -webkit-animation: popupCartWrapperAnim 0.8s forwards;
            animation: popupCartWrapperAnim 0.8s forwards;
}
.cartPopedUp #displayCart {
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}

.popupCartClose {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    opacity: 0.5;
}
.popupCartPriceSumarry {
    margin: 30px 0 120px 0;
}
.popupCartPriceSumarry p:nth-child(1) {
    color: #5E5E5E !important;
    border-right: 1px solid #333333;
    font-size: 17px;
    font-family: alwyn-new-rounded-web, sans-serif;
    float: left;
    margin-left: 30px;
    margin-right: 20px;
    padding-right: 20px;
}
.popupCartPriceSumarry p:nth-child(2) {
    color: #ED1845 !important;
    font-size: 25px;
    font-family: filson-soft, sans-serif;
    float: left;
    margin-top: -7px;
    font-weight: 200;
}
.displayCartItemsSummary {
    border: 1px solid rgba(51, 51, 51, 0.71);;
    height: 79%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
}
/* width */
.displayCartItemsSummary::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.displayCartItemsSummary::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.displayCartItemsSummary::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.displayCartItemsSummary::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.popupCartItem {
    margin: 20px 0;
}
.popupCartItem:not(:last-child) {
    margin-bottom: 80px;
}
.popupCartItemLineOne {
    border-bottom: 1px solid #ED1845;
    width: calc(100% - 40px);
    padding-bottom: 7px;
    margin: 0 20px;
    color: white !important;
    font-size: 16px;
    font-family: filson-soft, sans-serif;
}
.popupCartItemLineOne > a {
    color: white !important;
    width: 100%;
    display: flex;
}
.popupCartItemLineOne > a:hover {
    color: white !important;
    text-decoration: none;
}
.popupCartItemLineTwo {
    width: calc(100% - 40px);
    padding: 7px 0;
    margin: 0 20px;
    color: #ED1845 !important;
    font-size: 16px;
    font-family: filson-soft, sans-serif;
}
.popupCartItemLineTwo > p {
    float: left;
}
.popupCartItemLineTwo input {
    border-radius: 100px;
    background-color: black;
    border: 1px solid rgb(168, 168, 168);
    width: 40px;
    height: 30px;
    color: white !important;
    text-align: center;
    font-size: 12px;
}
.popupCartItemLineTwo button {
    width: 30px;
    height: 30px;
    padding: 0;
    margin: 0 0 0 10px;
    background-color: rgb(237, 24, 69);
    border: none;
    border-radius: 100px;
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
}
.popupCartItemLineTwo button:hover {
    -webkit-filter: brightness(1);
            filter: brightness(1);
}
.popupCartConfirmForm {
    margin-top: 40px;
}
.popupCartConfirmFormText {
    width: 210px;
    float: left;
    padding-left: 10px;
    border-left: 1px solid #333333;
    margin-left: 10px;
}
.popupCartConfirmForm a {
    font-size: 17px;
    line-height: 20px;
    color: #aaa !important;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 200;
}
.controlCartEmptyText {
    font-size: 20px;
    line-height: 20px;
    color: #aaa !important;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 300;
    text-align: center;
    margin-top: 100px;
}
.popupCartConfirmForm a:hover {
    color: #aaa !important;
    text-decoration: none;
}
.popupCartConfirmForm .cartSubmitButton {
    background-color: rgba(237,24,69,0.75);
    border-radius: 30px;
    border: none;
    color: white !important;
    width: 165px;
    height: 42px;
    font-family: filson-soft, sans-serif;
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    float: right;
    line-height: 42px;
}
.uberCheckbox {
    margin-top: 10px;
    float: left;
    -webkit-appearance: none !important;
            appearance: none !important;
    border-radius: 100px;
    background-color: #383838;
    width: 31px;
    height: 31px;
    position: relative;
}
.uberCheckbox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: transparent;
    transition: all 0.2s;
    border: 3px solid white;
}
.uberCheckbox:checked::before {
    border-color: #ED1845;
    -webkit-transform: rotate(-45deg) translate(-50%, -50%);
    transform: rotate(-45deg) translate(-50%, -50%);
    height: 56% !important;
    width: 113%;
    border-top-style: none;
    left: 60%;
    top: -23%;
    border-right-style: none;
    border-radius: 0;
}
.popupCartConfirmFormWrapperAnim {
    -webkit-animation: popupCartConfirmFormShake 1s;
            animation: popupCartConfirmFormShake 1s;
}
@media (max-width: 600px) {
    #popupCart {
        width: 365px;
    }
    .cartSubmitButton {
        margin: 0 auto;
        float: none !important;
        display: table;
    }
    .popupCartConfirmFormText {
        margin-bottom: 10px;
    }
}
@media (max-width: 375px) {
    #popupCart {
        top: 0 !important;
        -webkit-transform: scale(1) !important;
                transform: scale(1) !important;
        left: 0;
        height: 100vh;
        width: 100%;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 90px;
    }
    .uberCheckbox {
        margin-left: 20px;
    }
}
#popupWrapper {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1032;
    transition: 0.8s;
}
#popup {
    position: absolute;
    top: 105%;
    left: 50%;
    -webkit-transform: translateX(-50%) scale(0.3);
            transform: translateX(-50%) scale(0.3);
    /*height: 94%;*/
    border-radius: 15px;
    background-color: #232323;
    border: 1px solid #333333;
    padding: 50px;
    width: 90%;
    min-height: 250px;
    max-width: 580px;
    max-height: 680px;
    transition: 0.8s;
}
.cartPopedUp #popup {
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
}
#popupConfirm {
    background-color: #ED1845;
    position: absolute;
    bottom: 50px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
            transform: translate(-50%, -50%) scale(1);
    text-align: center;
    border-radius: 30px;
    border: none;
    color: white !important;
    width: 165px;
    height: 42px;
    font-family: filson-soft, sans-serif;
    font-size: 14px;
    font-weight: 200;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;
    float: right;
    line-height: 42px;
    cursor: pointer;
}
#popupConfirm:hover {
    color: #aaa !important;
}
#popupText {
    color: white;
    text-align: center;
    font-size: 20px;
    padding-bottom: 50px;
}
@media (min-width: 640px) {
    
}
.rowSection .homeTileDivider {
    margin-top: 30px;
    margin-bottom: 40px;
}
.rowSectionTitle {
    padding: 0;
    margin-left: 0 !important;
    overflow: visible;
    white-space: nowrap;
    float: left;
}
.sectionIconRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    height: 46px;
}
.termText, .termHeader {
    font-weight: 300;
    line-height: 36px;
    font-family: myriad-pro, sans-serif;
    font-size: 18px;
    text-align: justify;
}
.termHeader {
    color: #ED1845;
}
.termList li {
    list-style: none;
    position: relative;
}
.termList li::before {
    font-size: 16px;
    content: '○';
    position: absolute;
    left: -16px;
}
@media (max-width: 993px) {
    .rowSectionTitle {
        margin: 0 auto !important;
        flex: 0 0 80%;
    }
}
.fastShop {
    min-height: 100vh;
}
.fastShop .homeTileVerticalDivider {
    display: none;
}
.fastShopTableWrapper {
    max-width: 96vw;
    overflow-x: scroll;
    margin: 0 auto;
}
@media (min-width: 992px) {
    .fastShopTableWrapper {
        overflow-x: hidden;
    }
}
.fastShopTable {
    min-width: 700px;
}
.fastShopTable table {
    width: 100%;
    margin-bottom: 400px;
}
.fastShopTable table td:first-child {
    border-left: 1px dotted #A0A0A0;
}
.GridText {
    position: relative;
    font-style: italic;
    font-size: 17px;
    font-weight: 300;
    color: black;
}
.GridTextWrapper {
    position: relative;
}
.GridTextCross {
    position: absolute;
    top: 0;
    right: 0;
}
.GridTextCross:before, .GridTextCross:after {
    position: absolute;
    right: 25px;
    top: 14px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #333;
    border-radius: 1px;
    display: none;
}
.GridTextCross.show::before, .GridTextCross.show::after {
    display: inline;
}
.GridTextCross:before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}
.GridTextCross:after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}
.GridText::-webkit-input-placeholder {
    color: #A0A0A0;
}
.GridText:-ms-input-placeholder {
    color: #A0A0A0;
}
.GridText::placeholder {
    color: #A0A0A0;
}
.fastShopTable th {
    height: 76px;
    color: white;
    background-color: #ED1845;
    border: 1px dotted white;
    text-transform: uppercase;
    /*border: 1px dotted #ED1845;*/
    border-spacing: 0px;
    text-align: center;
    transition: all 0.25s;
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 17px;
    line-height: 17px;
    font-family: myriad-pro, sans-serif;
    font-weight: bold;
    cursor: pointer;
    margin-left: -1px;
    display:flex !important;
    justify-content:center;
    align-items:center;
}
.fastShopTable th::before {
    transition: all 0.25s;
    width: 7px;
    height: 7px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    content: "";
    /* display: none; */
    opacity: 0;
    position: absolute;
    right: 9px;
    top: calc(50% - 2px);
    -webkit-transform: scale(0);
            transform: scale(0);
}
.fastShopTable table.reverse th::before {
    -webkit-transform: rotate(225deg) !important;
            transform: rotate(225deg) !important;
    top: calc(50% - 5px);
}
.fastShopTable table tr {
    display: flex;
    flex: 1 1;
}
.fastShopTable table tr td {
    display:flex;
    align-items:center;
    overflow: hidden;
}
.fastShopTable table[class^="orderBy"] th::before {
    -webkit-transform: scale(1) rotate(45deg);
            transform: scale(1) rotate(45deg);
}
.fastShopTable table.orderBy1 th:nth-child(1)::before {
    /*display: block;*/
    opacity: 1;
    /* scale: 1; */
}
.fastShopTable table.orderBy2 th:nth-child(2)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy3 th:nth-child(3)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy4 th:nth-child(4)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy5 th:nth-child(5)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy6 th:nth-child(6)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy7 th:nth-child(7)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy8 th:nth-child(8)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable th:nth-child(1) {
    /*border-top-left-radius: 10px;*/
    border-left: 1px dotted white;
    margin-left: 0;
}
.fastShopTable th:nth-child(8) {
    border-top-right-radius: 10px;
}
.fastShopTable td:nth-child(1) {
    border-left: 1px dotted #A0A0A0;
    margin-left: 0;
}
.fastShopTable th:nth-child(1), .fastShopTable td:nth-child(1) {
    width: 13%;
}
.fastShopTable th:nth-child(2), .fastShopTable td:nth-child(2) {
    width: 20%;
}
.fastShopTable th:nth-child(3), .fastShopTable td:nth-child(3) {
    width: 10%;
}
.fastShopTable th:nth-child(4), .fastShopTable td:nth-child(4) {
    width: 9%;
}
.fastShopTable th:nth-child(5), .fastShopTable td:nth-child(5) {
    width: 9%;
    min-width: 64px;
}
.fastShopTable th:nth-child(6), .fastShopTable td:nth-child(6) {
    width: 19%;
    min-width: 134px;
}
.fastShopTable th:nth-child(7), .fastShopTable td:nth-child(7) {
    width: 15%;
}
.fastShopTable th:nth-child(8), .fastShopTable td:nth-child(8) {
    width: 10%;
    min-width: 70px;
}
.fastShopTable table.orderBy1 th:nth-child(1), .fastShopTable table.orderBy1 td:nth-child(1) {
    width: calc(13% + 50px);
}
.fastShopTable table.orderBy2 th:nth-child(2), .fastShopTable table.orderBy2 td:nth-child(2) {
    width: calc(20% + 50px);
}
.fastShopTable table.orderBy3 th:nth-child(3), .fastShopTable table.orderBy3 td:nth-child(3) {
    width: calc(10% + 50px);
}
.fastShopTable table.orderBy4 th:nth-child(4), .fastShopTable table.orderBy4 td:nth-child(4) {
    width: calc(9% + 50px);
}
.fastShopTable table.orderBy5 th:nth-child(5), .fastShopTable table.orderBy5 td:nth-child(5) {
    width: calc(9% + 50px);
}
.fastShopTable table.orderBy5 th:nth-child(5) {
    padding-right: 25px;
}
.fastShopTable table.orderBy6 th:nth-child(6), .fastShopTable table.orderBy6 td:nth-child(6) {
    width: calc(19% + 50px);
}
.fastShopTable table.orderBy7 th:nth-child(7), .fastShopTable table.orderBy7 td:nth-child(7) {
    width: calc(15% + 50px);
}
.fastShopTable table.orderBy8 th:nth-child(8), .fastShopTable table.orderBy8 td:nth-child(8) {
    width: calc(10% + 50px);
}
.fastShopTable td {
    border-right: 1px dotted #A0A0A0;
    border-bottom: 1px dotted #A0A0A0;
    height: 70px;
    transition: all 0.25s;
    background-color: white;
    padding-left: 10px;
    color: #A0A0A0;
    border: 1px dotted #A0A0A0;
    margin-top: -1px;
    margin-left: -1px;
}
.fastShopTable td:nth-child(5) {
    cursor: pointer;
    color: #ED1845;
}
.fastTableControls {
    height: 46px;
    margin-bottom: 1px;
}
.fastTableControls > * {
    float: left;
}
.fastTableControls > .GridButton {
    width: 50px;
    height: 46px;
    border: none;
    color: white;
}
.fastTablePagination > .fastPagination {
    width: 50px;
    height: 46px;
    border: none;
    color: white;
    float: left;
    background-color: #ED1845;
    border-radius: 3px;
    margin-right: 3px;
    padding: 10px;
    padding-left: 20px;
}
.fastTableControls > .GridButton > a {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    line-height: 46px;
    display: block;

}
.fastTableControls > button:nth-child(1) {
    border-top-left-radius: 10px;
    background-color: #ED1845;
    margin-right: 3px;
}
.fastTableControls > button:nth-child(2),
.fastTableControls > div:nth-child(3),
.fastTableControls > button:nth-child(4),
.fastTableControls > button:nth-child(5) {
    background-color: #ED1845;
    margin-right: 3px;
}
.fastTableControls > button:nth-child(5) {
    border-top-right-radius: 5px;
}
.fastTableControls input.GridText {
    width: 362px;
    height: 46px;
    padding: 0 15px;
    margin: 0;
    border: none;
    border-radius: 5px;
}
.GridButton {
    transition: 0.2s;
}
.GridButton:hover {
    background-color: #474747 !important;
    transition: 0.2s;
}
.GridSelect {
    -webkit-appearance: none;
    appearance: none;
    width: 44px;
    height: 46px;
    text-align: center;
    background-color: #ED1845;
    color: white;
    font-size: 17px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    font-style: italic;
    border: none;
    line-height: 46px;
    position: relative;
    cursor: pointer;
}
.GridSelect.show::before {
    content: "";
    width: 44px;
    height: 184px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 9;
}
.GridSelect > .GridOption {
    display: none;
    border-left: transparent;
    border-right: transparent;
    border-width: 0px;
    border-style: solid;
    cursor: pointer;
}
.GridSelect > .GridOption:hover {
    color: rgb(244, 116, 143);
    transition: 0.2s;    
}
.GridSelect > .GridOption.selected {
    color: rgb(244, 116, 143);
}
.GridSelect.show > .GridOption:nth-child(1) {
    margin-top: -46px;
}
.GridSelect.show > .GridOption:nth-child(2) {
    border-top: 1px solid #757575;
}
.GridSelect.show > .GridOption:nth-child(3) {
    border-top: 1px solid #757575;
}
.GridSelect.show > .GridOption:nth-child(4) {
    border-top: 1px solid #757575;
}
.GridSelect.show > .GridOption {
    width: 44px;
    height: 46px;
    display: block;
    float: left;
    background-color: #ED1845;
    line-height: 46px;
    text-align: center;
    font-size: 17px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    font-style: italic;
    position: relative;
    z-index: 10;
}
.fastShopTable table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
.fastShopTable table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
.fastShopTable table.orderBy1 tbody tr td:nth-child(1) {
    background-color: rgba(214,214,214, 0.77);
    color: black;

}
.fastShopTable table.orderBy2 tbody tr td:nth-child(2) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy3 tbody tr td:nth-child(3) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy4 tbody tr td:nth-child(4) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy5 tbody tr td:nth-child(5) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy6 tbody tr td:nth-child(6) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy7 tbody tr td:nth-child(7) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy8 tbody tr td:nth-child(8) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.UtilsNavbarSpacer {
    width: 100%;
    height: 90px;
}
#LoadingOverlayElement {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #d1d1d1;
    opacity: 75%;
    transition: 1s;
    z-index: 2000;
}
#LoadingOverlayElement > img {
    width: 10%;
    height: 10%;
    min-width: 50px;
    min-height: 50px;
    position: fixed;
    left: 45%;
    top: 45%;
    -webkit-animation-name: spin;
            animation-name: spin;
    -webkit-animation-duration: 5000ms;
            animation-duration: 5000ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; 
}
@-webkit-keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}
@keyframes spin {
    from {
        -webkit-transform:rotate(0deg);
                transform:rotate(0deg);
    }
    to {
        -webkit-transform:rotate(360deg);
                transform:rotate(360deg);
    }
}

.sidebar-register-text {
    font-family: filson-soft, sans-serif;
    font-weight: 200;
    font-size: 15px;
}
@media (max-width: 640px) {
    
}
@media (min-width: 640px) {
    
}
@media (min-width: 787px) {

}
@media (min-width: 897px) {
    .HomePageSection {
        margin-left: 55px;
        margin-right: 55px;
    }
}
@media (min-width: 1060px) {
    .HomePageSection {
        margin: 0 auto;
        max-width: 80vw;
    }
}
@media (min-width: 1160px) {
    .HomePageSection {
        max-width: 65vw;
    }
}
@-webkit-keyframes trackPagePulsing{
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes trackPagePulsing{
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.trackPage {
    z-index: 10;
    flex: 0 0 90%;
    max-width: 90%;
}
.trackPlayerWrapper {
    position: relative;
    width: 450px;
    height: 440px;
    margin: 0 auto;
}
.trackPlayerImage {
    width: 450px;
    height: 440px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #333;
    z-index: -1;
}
.audioControls {
    width: 100%;
    height: 100%;
}
.audioTimeline {
    position: absolute;
    bottom: -5px;
    left: 0;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    width: 100%;
    background-color: transparent;
    -webkit-appearance: none;
            appearance: none;
}
.audioPlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: flex;
    z-index: 10;
}
.audioPrevious {
    width: 72px;
    height: 72px;
    margin-top: 20px;
    cursor: pointer;
}
.audioToggleWrapper {
    width: 112px;
    height: 112px;
    border: 1px solid white;
    border-radius: 70px;
}
.audioToggle {
    cursor: pointer;
    width: 112px;
    height: 112px;
}
.audioNext {
    width: 72px;
    height: 72px;
    margin-top: 20px;
    cursor: pointer;
}
.trackPagePulsing {
    -webkit-animation: trackPagePulsing 1.5s infinite;
            animation: trackPagePulsing 1.5s infinite;
}


#audioTimeLineGrey {
    position: absolute;
    bottom: -15px;
    left: 0;
    height: 2px;
    background-color: rgba(0,0,0,.3);
    z-index: 90;
}
#audioTimeLineRed {
    position: absolute;
    bottom: -15px;
    left: 0;
    height: 2px;
    background-color: #ED1845;
    z-index: 100;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.audioVolumeWrapper {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 215px;
    height: 28px;
    cursor: pointer;
}
#audioVolumeImage {
    width: 39px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/assets/images/speakeron.svg");
}
#audioVolumeImage:hover {
    background-image: url("/assets/images/speakeroff.svg");
}
#audioVolumeImage.muted {
    background-image: url("/assets/images/speakeroff.svg");
}
#audioVolumeImage.muted:hover {
    background-image: url("/assets/images/speakeron.svg");
}
.audioVolumeWrapper:hover .audioVolumeSliderWrapper {
    display: block;
}
.audioVolumeSliderWrapper {
    position: absolute;
    top: 0;
    left: 39px;
    width: calc(100% - 39px);
    display: none;
    height: 100%;
}
#audioVolume::-webkit-slider-runnable-track {
    height: 4px !important;
    border-radius: 0;
}
#audioVolume::-moz-range-track {
    height: 4px !important;
    border-radius: 0;
}
#audioVolume::-ms-track {
    height: 4px !important;
    border-radius: 0;
}
#audioVolume {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
#audioVolume::-webkit-slider-runnable-track {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-top: 0;
}
#audioVolumeRed {
    position: absolute;
    top: 50%;
    left: 0;
    height: 4px !important;
    background-color: #ED1845;
    z-index: 100;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
/*@-moz-document url-prefix() {
    #audioVolumeRed {
        bottom: 9px;
    }
}*/
#audioVolume::-webkit-slider-thumb {
    visibility: hidden;
}
#audioVolume::-moz-range-thumb {
    visibility: hidden;
}
#audioVolume::-ms-thumb {
    visibility: hidden;
}

@-moz-document url-prefix() {
    .audioTimeline {
        bottom: -8px;
    }
}


input[type=range] {
    height: 12px;
    -webkit-appearance: none;
            appearance: none;
    width: 100%;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background: white;
    border-radius: 5px;
    margin-top: 5px;
}
input[type=range]::-webkit-slider-thumb {
    height: 12px;
    width: 2px;
    margin-top: -5px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 110;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    -moz-transition: 0.2s;
    transition: 0.2s;
    background: white;
    border-radius: 5px;
    margin-top: 5px;
}
input[type=range]::-moz-range-thumb {
    height: 12px;
    width: 2px;
    margin-top: -5px;
    background: #FFFFFF;
    cursor: pointer;
    border: none;
    border-radius: 0;
    appearance: none;
    z-index: 110;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    -ms-transition: 0.2s;
    transition: 0.2s;
    background: white;
    border-radius: 5px;
    margin-top: 5px;
}
input[type=range]::-ms-fill-lower {
    /*background: #3071A9;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000; */
}
input[type=range]::-ms-fill-upper {
    /*background: #3071A9;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000; */
}
input[type=range]::-ms-thumb {
    height: 12px;
    width: 2px;
    margin-top: -5px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 110;
}




/*.audioTimeLine::-webkit-slider-thumb, .audioTimeLine::-moz-range-thumb, .audioTimeLine::-ms-thumb {
    height: 10px;
    width: 3px;
    background-color: white;
}
.audioTimeLine::-webkit-slider-runnable-track, .audioTimeLine::-moz-range-track, .audioTimeLine::-ms-track  {
    height: 3px;
    background-color: white;
}*/
.audioTime {
    position: absolute;
    bottom: -42px;
    right: 0;
    color: white;
    font-size: 12px;
    line-height: 14px;
    font-family: futura-pt, sans-serif;
    font-weight: 300;
}
.trackPlayerAudio {
    position: absolute;
    bottom: 10px;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);
    z-index: 11;
}
.trackInfo {
    color: white;
    text-align: center;
    margin-left: auto;
}
.trackInfo.mobile {
    color: black;
}
.trackInfo h2 {
    font-family: ff-prater-script-web, sans-serif;
    font-weight: 400;
    font-size: 68px;
    line-height: 82px;
    text-transform: lowercase;
}
.trackInfo h3 {
    color: #A0A0A0;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 26px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    margin-top: 50px;
}
.trackInfo h4 {
    color: #ED1845;
    font-size: 22px;
    line-height: 26px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.songList {
    margin-bottom: 100px;
    width: 50%;
    margin-left: 10vw;
}
.songListTitle {
    font-size: 35px;
    height: 45px;
    line-height: 42px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.songList p {
    text-align: left;
    font-size: 18px;
    line-height: 36px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    color: #A0A0A0;
    margin-bottom: 0;
    padding-left: 5px;
    white-space: nowrap;
}
.songListImage {
    width: 49px;
    height: 39px;
    margin-left: -55px;
    float: left;
}
.songListItem {
    cursor: pointer;
}
.songListItem.playing::before {
    -webkit-animation: trackPagePulsing 1.5s infinite;
            animation: trackPagePulsing 1.5s infinite;
}
.songListItem.playing, .songListItem.paused {
    position: relative;
}
.songListItem.playing::before, .songListItem.paused::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -25px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 20px;
    -webkit-filter: brightness(60%);
            filter: brightness(60%);
    background: url("/assets/images/musicicons_play-white.png") no-repeat;
    background-size: 200% 200%;
    background-position: center;
}
.songListItem.paused::before {
    background: url("/assets/images/musicicons_pause-white.png") no-repeat;
    background-size: 200% 200%;
    background-position: center;
}
.relatedTitles {
    margin: 0 auto;
    padding: 0;
    width: 380px;
    min-width: 380px;
    margin-bottom: 50px;
}
.relatedTitle {
    width: 165px;
    height: 165px;
    padding: 0px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 40px;
    overflow: hidden;
    flex: none;
    box-shadow: 6px 6px 0px rgba(0,0,0,0.15);
    /* width: calc(50% - 20px); */
    display: block;
    float: left;
}
.relatedTitle:nth-child(odd) {
    margin-left: 0;
    margin-right: 20px;
}
.relatedTitle:nth-child(even) {
    margin-right: 0;
    margin-left: 20px;
}
.relatedTitle img {
    width: 165px;
    height: 165px;
    object-fit: cover;
}
.trackPageSongList {
    flex: 0 0 55%;
    max-width: 55%;
}
.trackPageRelatedList {
    flex: 0 0 45%;
    max-width: 45%;
}
.relatedTitleWrapper {
    display: flex;
}
.trackPage .commentPopupWapper {
    position: relative !important;
    margin: 0 auto;
    margin-bottom: 90px;
    right: unset;
    bottom: unset;
    width: 50px;
    height: 50px;
    border: 1px solid #A0A0A0;
    z-index: 1;
}
.trackPage .commentPopupWapper::after {
    color: #A0A0A0;
    content: '?';
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 23px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.trackPage .commentPopupWapper .commentPopupInner {
    /*display: none;*/
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    -webkit-transform: translate(-50%, 5px);
            transform: translate(-50%, 5px);
    padding: 10px;
    border-radius: 5px;
}
.trackPage .commentPopupWapper:hover .commentPopupInner {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.6s linear;
}

@media (max-width: 992px) {
    .trackPlayerWrapper {
        max-width: 450px;
    }
    .trackPage {
        flex: 0 0 95%;
        max-width: 95%;
    }
    .p-sm-0 {
        padding: 0;
    }
    .trackPlayerImage {
        width: 100%;
        height: auto;
    }
    .trackInfo.mobile {
        margin: 0 auto;
    }
    .songList {
        margin: 0;
        margin-left: calc(50% - 82.5px);
        width: 165px;
    }
    .relatedTitle {
        width: 165px;
        height: 165px;
        margin: 0 auto !important;
        margin-bottom: 50px !important;
    }
}

@media (max-width: 786px) {
    .trackPageSectionDivider {
        border-bottom: 1px solid #A0A0A0;
        width: 50%;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: block;
    }
    .trackPlayerBlackSection {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .trackPlayerWrapper {
        width: 450px;
        height: unset;
        padding-top: 450px;
        margin: 0 auto;
    }
    .trackInfo {
        display: none;
    }
    .trackInfo.mobile {
        display: block;
    }
    .relatedTitlesList {
        width: 100%;
    }
    .trackPageSongList {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .trackPageRelatedList {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .relatedTitleWrapper {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .songListTitleWrapper {
        margin: 0 auto;
    }
    .trackPage .commentPopupWapper {
        border: 1px solid #A0A0A0;
    }
    .trackPage .commentPopupWapper::after {
        color: #A0A0A0;
    }
}
@media (max-width: 500px) {
    .trackPlayerWrapper {
        width: 320px;
        padding-top: 320px;
    }
    .relatedTitles {
        min-width: 320px;
        width: 320px;
        margin: 0 auto;
    }
    .trackPage {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 340px) {
    .trackPlayerWrapper {
        width: 310px;
        padding-top: 310px;
    }
}
@media (min-width: 787px) {
    .trackPlayerBlackSection {
        flex: 0 0 50%;
        max-width: 450px;
    }
    .trackInfo {
        display: block;
    }
    .trackInfo.mobile {
        display: none;
    }
    .trackPageSectionDivider {
        display: none;
    }
    .relatedTitleWrapper {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width:901px) and (max-width:992px) {
    .trackInfo {
        max-width: calc(100% - 450px);
    }
}

@media (min-width: 787px) and (max-width:900px) {
    .trackPage {
        margin-left: 0;
        margin-right: 0;
    }
    .trackInfo {
        max-width: calc(100% - 400px);
    }
    .trackPlayerBlackSection {
        max-width: 400px;
    }
    .trackPlayerWrapper {
        max-width: 400px;
        max-height: 390px;
        margin-top: 25px;
    }
    .trackPageSongList {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .trackPageRelatedList {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 993px) {
    .trackPlayerWrapper {
        width: 450px;
        height: 440px;
    }
    .trackPlayerImage {
        width: 450px;
        height: 440px;
    }
    .trackInfo .CDTilePriceWrapper {
        margin-top: -50px;
    }
    .trackInfo {
        max-width: 500px;
        margin-right: 0;
    }
    .trackPlayerBlackSection {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1050px) {
    .trackInfo {
        margin-right: 30px;
    }
}
@media (min-width: 1100px) {
    .trackInfo {
        margin-right: 50px;
    }
}
@media (min-width: 1200px) {
    .trackInfo {
        margin-right: 100px;
    }
    .trackPage {
        flex: 0 0 82%;
        max-width: 82%;
    }
}
@media (min-width: 1350px) {
    .trackInfo {
        max-width: 750px;
        min-width: 200px;
    }
}
.registerForm {
    margin-bottom: 200px;
}
.registerForm form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.registerForm div.registerFormCopy {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
input.registerInput {
    margin-bottom: 52px;
    max-width: calc(33.33% - 17px);
    width: 33.33%;
    background-color: #F7F7F7;
    height: 55px;
}
.registerformCheckbox {
    -webkit-appearance: none;
            appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #ED1845;
    border-image-source: linear-gradient(45deg, rgba(237,24,69,1) 0%, rgba(237,24,69,1) 85%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
    border-image-slice: 1;
    background-color: transparent;
    position: relative;
}
.registerformCheckbox:checked::after {
    content: "";
    border-top: 1px solid #ED1845;
    border-right: 1px solid #ED1845;
    position: absolute;
    width: 20px;
    height: 5px;
    display: block;
    -webkit-transform: rotate(-225deg);
            transform: rotate(-225deg);
    margin-top: -4px;
}
.registerFormButtonContinue, .registerFormButtonSave {
    border-radius: 10px;
    border: 1px solid #ED1845;
    text-align: center;
    height: 57px;
    float: right;
    padding-left: 20px;
    padding-right: 20px;
}
.registerFormButtonContinue {
    color: #ED1845;
    background-color: transparent;
}
.registerFormButtonSave {
    background-color: #ED1845;
    color: white;
}
div.inputWrongInfo input.registerInput, div.inputWrongInfo input.basicInput {
    max-width: 100%;
    width: 100%;
    background-color: #ED1845;
    color: white !important;
}
div.inputAnnotated input.registerInput, div.inputAnnotated input.basicInput {
    max-width: 100%;
    width: 100%;
}
div.inputWrongInfo input.registerInput::-webkit-input-placeholder, div.inputWrongInfo input.basicInput::-webkit-input-placeholder {
    color: white !important;
}
div.inputWrongInfo input.registerInput:-ms-input-placeholder, div.inputWrongInfo input.basicInput:-ms-input-placeholder {
    color: white !important;
}
div.inputWrongInfo input.registerInput::placeholder, div.inputWrongInfo input.basicInput::placeholder {
    color: white !important;
}
div.inputWrongInfo, div.inputAnnotated {
    position: relative;
    max-width: calc(33.33% - 17px);
    width: 33.33%;
}
div.inputWrongInfo > p {
    color: #ED1845;
}
div.inputWrongInfo > p, div.inputAnnotated > p {
    position: absolute;
    left: 16px;
    top: 70px;
    font: 400 13.3333px Arial;
    z-index: -1;
    -webkit-animation: slideOutInputWrongInfo 0.5s forwards;
            animation: slideOutInputWrongInfo 0.5s forwards;
}
@media (max-width: 992px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: 100%;
        width: 100%;
        margin-bottom: 45px;
        height: 55px;
    }
    .registerForm {
        width: 100%;
        margin: 0 auto;
    }
    .registerForm.bottomPart {
        margin-bottom: 300px;
    }
    input.half-input {
        width: calc(50% - 17px);
    }
    .registerFormButtonSave div {
        line-height: 1;
    }
    .registerformCheckbox {
        width: 0 !important;
        height: 0 !important;
        padding-bottom: 11px !important;
        padding-right: 11px !important;
    }
}
@media (max-width: 993px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: calc(33.33% - 17px);
        width: 33%;
    }
    .registerPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
}
@media (max-width: 930px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: calc(50% - 17px);
        width: 50%;
    }
}
@media (max-width: 786px) {
    .registerFormButtonSave {
        margin-top: 20px;
    }
}
@media (max-width: 600px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: 100%;
        width: 100%;
    }
}
@media (max-width: 525px) {
    .registerPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
}
@-webkit-keyframes slideOutInputWrongInfo {
    0% {
        top: 0;
    }
    100% {
        top: 70px;
    }
}
@keyframes slideOutInputWrongInfo {
    0% {
        top: 0;
    }
    100% {
        top: 70px;
    }
}
.contactPage {
    min-height: 85vh;
}
.contactInfo p, .contactInfo h4 {
    margin-bottom: 0;
}
@media (max-width: 993px) {
    .contactInfo p, .contactInfo h4 {
        font-size: 18px;
        line-height: 28px;
    }
    .termText.color-red {
        white-space: nowrap;
    }
}
@media (max-width: 550px) {
    .contactInfo p, .contactInfo h4 {
        font-size: 14px;
    }   
}
.cartPage {
    min-height: calc(100vh - 96px);
}
.cartPage .tileSectionImage {
    height: 34px;
    margin-top: 7px;
}
.cartPage .homeTileDivider:nth-child(1) {
    display: none;
}
.cartItems {
    width: 100%;
}
.cartItem {
    min-height: 165px;
    overflow: auto;
}
.cartItemImageWrapper {
    width: 165px;
    min-height: 165px;
    float: left;
    display:flex;
    justify-content:center;
    align-items:center;
}
.cartItemImage {
    width: 165px;
    height: 165px;
    border-radius: 10px;
    box-shadow: 4px 4px 0px rgba(0,0,0,0.15);
    object-fit: cover;
}
.cartItemDescWrapper {
    position: relative;
    float: left;
    min-height: 165px;
    width: calc(100% - 360px);
    margin-left: 30px;
}
.cartItemDescText, .cartItemDescCode {
    color: #A0A0A0;
    text-transform: uppercase;
    font-size: 16px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.cartItemDescHeader {
    min-height: 86px;
    color: #262626;
    font-size: 60px !important;
    line-height: 86px !important;
    font-family: ff-prater-script-web, sans-serif !important;
    font-weight: 400 !important;
    text-transform: lowercase;
}
.cartItemDescHeader:link {
    text-decoration: none;
}
.cartItemDescText {
    min-height: 41px;
    margin-bottom: 10px;
}
.cartItemDescCode {
    min-height: 24px;
    margin-bottom: 0;
}
.cartItemControlsWrapper {
    position: relative;
    float: left;
    height: 165px;
    width: 165px;

}
.cartItemControlsClose {
    width: 20px;
    height: 24px;
    float: right;
    position: absolute;
    right: 0;
    top: 50%;
}
.cartItemControlsPrice {
    position: absolute;
    text-align: right;
    font-size: 36px;
    font-family: filson-soft, sans-serif;
    bottom: 0;
    right: 0;
}
.cartItemImage.noShadow {
    box-shadow: none;
}
.cartCrumbs {
    margin-top: 20px;
    width: 100%;
    height: 35px;
    min-width: 215px;
}
.cartCrumb {
    float: left;
}
.cartCrumb::after {
    content: "→";
    float: right;
    display: block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.cartCrumb.noAfter::after {
    content: none;
    display: none;
}
.cartCrumb:nth-child(2) img {
    -webkit-filter: grayscale(1);
            filter: grayscale(1);
}
.cartCrumb img {
    width: 19px;
    height: 17px;
    margin-right: 5px;
    margin-top: -2px;
}
.profilePage .cartCrumb::after {
    content: "-";
    text-align: center;
}
.icon-red {
    -webkit-filter: invert(22%) sepia(62%) saturate(7187%) hue-rotate(339deg) brightness(95%) contrast(96%) !important;
            filter: invert(22%) sepia(62%) saturate(7187%) hue-rotate(339deg) brightness(95%) contrast(96%) !important;
}
.icon-black {
    -webkit-filter: brightness(0) !important;
            filter: brightness(0) !important;
}
.cartPriceSummary .cartItemControlsWrapper .cartItemControlsPrice {
    font-weight: 800;
}
.cartSaleText {
    font-size: 16px;
    text-transform: unset;
}
@media (max-width: 1080px) {
    .cartCrumbs {
        width: 415px;
        height: 100px;
    }
    .cartCrumb {
        margin-top: 20px;
    }
    .cartPage .collapsable {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
@media (max-width: 993px) {
    .rowSectionTitle {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .cartPage .homeTileVerticalDivider.visibility-none {
        display: none;
    }
    .cartPage .registerFormButtonContinue {
        margin-right: 1.5rem !important;
    }
    .cartPage .registerFormButtonSave, .cartPage .registerFormButtonContinue {
        width: unset;
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
    .cartPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
    .cartCrumbs {
        width: 415px;
        height: 100px;
    }
}
@media (max-width: 786px) {
    .cartItemMobileImageWrapper {
        display: block;
    }
    .cartItemImageWrapper {
        display: none;
    }
    .cartPage {
        flex: 0 0 90% !important;
        max-width: 90% !important;
    }
    .cartPriceSummary .cartItemImageWrapper {
        display: block;
    }
    .cartPriceSummary {
        min-height: 165px;
    }
    .cartPriceSummary .cartItemImageWrapper img {
        width: 250px;
        height: 250px;
    }
    .cartPriceSummary .cartItemImageWrapper img {
        width: 165px;
        height: 165px;
        margin: 0 auto;
        display: block;
    }
    .orderPageButtonWrapper {
        flex-direction: column;
    }
}
@media (min-width: 526px) and (max-width: 786px) {
    .cartCrumbs {
        width: 208px;
        height: 200px;
    }
    .cartItem {
        position: relative;
    }
    .cartItemDescWrapper {
        width: 250px;
        margin: 0;
    }
    .cartItemControlsWrapper {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .cartItemMobileImageWrapper {
        width: 100%;
        padding-top: 100%;
        position: relative;
        margin-top: 10px;
    }
    .cartItemImage {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    .cartItemDescHeader {
        text-align: center;
    }
    .cartItemDescText {
        text-align: center;
    }
    .cartItemDescCode {
        text-align: center;
    }
    .cartPriceSummary .cartItemDescWrapper {
        display: none;
    }
    .cartPage .registerFormButtonSave, .cartPage .registerFormButtonContinue {
        width: 327px;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
@media (max-width: 525px) {
    .cartPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
    .cartItem {
        width: 350px;
        margin: 0 auto;
    }
    .cartItemImage {
        width: 350px;
        height: 350px;
    }
    .cartItemDescWrapper {
        float: none;
        margin-left: 0;
        width: 100%;
    }
    .cartItemMobileImageWrapper {
        display: block;
        margin-top: 20px;
    }
    .cartItemImageWrapper {
        display: none;
    }
    .cartItemDescHeader {
        text-align: center;
    }
    .cartItemDescText {
        text-align: center;
    }
    .cartItemDescCode {
        text-align: center;
    }
    .cartItemControlsWrapper {
        width: 100%;
        height: 100px;
    }
    .cartItemControlsWrapper > * {
        right: 50%;
        -webkit-transform: translateX(50%);
                transform: translateX(50%);
    }
    .cartItemControlsClose {
        top: 20%;
    }
    .cartItemControlsPrice {
        width: 100%;
        text-align: center;
    }
    .cartCrumbs {
        width: 45%;
        height: 200px;
    }
    .cartItem {
        width: 225px;
        margin: 0 auto;
    }
    .cartItemImage {
        width: 225px;
        height: 225px;
    }
    .cartItemDescHeader {
        font-size: 45px !important;
    }
    .cartPriceSummary {
        min-height: 270px;
    }
    .cartPriceSummary .cartItemImageWrapper {
        width: 225px;
    }
    .cartPage .registerFormButtonSave, .cartPage .registerFormButtonContinue {
        width: 100%;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
@media (min-width: 787px) {
    .cartItemMobileImageWrapper {
        display: none;
    }
    .cartItemImageWrapper {
        display: block;
    }
    .cartPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
    .cartSaleText {
        font-size: 24px;
    }
}
@media (min-width: 1200px) {
    .cartPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
}
.DeliveryTerms .tileSectionImage {
    width: 29px !important;
    margin-left: -35px !important;
    margin-top: 10px;
}
.DeliveryTerms > .tileSection > .collapsable {
    padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
}
.DeliveryTerms > .tileSection > .homeTileDivider {
    flex: 0 0 100%;
}
.DeliveryTerms > .tileSection > .rowSectionTitle {
    flex: 0 0 100%;
    max-width: 100%;
}
.DeliveryTerms .homeTileVerticalDivider.visibility-none {
    display: none;
}
@media (max-width: 992px) {
    .DeliveryTerms > .tileSection {
        max-width: 80%;
    }
}
@media (max-width: 640px) {
    .DeliveryTerms {
        max-width: 80%;
    }
    .DeliveryTerms .tileSectionImage {
        width: 22px !important;
    }
    .DeliveryTerms > .tileSection > .collapsable {
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 550px) {
    .DeliveryTerms > .tileSection > .rowSectionTitle > h2 {
        font-size: 24px;
    }
}
@media (max-width: 475px) {
    .DeliveryTerms > .tileSection > .rowSectionTitle > h2 {
        font-size: 20px;
    }
}
@media (max-width: 410px) {
    .DeliveryTerms > .tileSection > .rowSectionTitle > h2 {
        font-size: 18px;
    }
    .DeliveryTerms .tileSectionImage {
        width: 18px !important;
        margin-top: 7px !important;
        margin-left: -25px !important;
    }
}   
.contactPage {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.contactPage .homeTileDivider {
    flex: 0 0 100%;
}
.contactPage .tileSectionImage {
    width: 33px !important;
    height: 35px !important;
    margin-left: -42px !important;
    margin-top: 7px;
}
.contactPage .homeTileVerticalDivider {
    display: none;
}
@media (max-width: 550px) {
    .contactPage {
        flex: 0 0 85%;
        max-width: 85%;
    }
}
@media (max-width: 450px) {
    .contactPage {
        flex: 0 0 90%;
        max-width: 90%;
    }
    .contactPage .rowSectionTitle {
        padding-left: 40px;
    }
}
@media (min-width: 1251px) and (max-width: 1700px) {
    .SearchPage {
        max-width: 90% !important;
        flex: 0 0 90% !important;
    }
}
@media (min-width: 800px) and (max-width: 1250px) {
    .SearchPage {
        max-width: 80% !important;
        flex: 0 0 80% !important;
    }
}
.RedButton {
    border-radius: 10px;
    border: 1px solid #ED1845;
    background-color: #ED1845;
    color: white;
    text-align: center;
    height: 57px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.SearchPage .RedButton {
    width: 145px;
}
.ButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.paymentPage .homeTileVerticalDivider {
    display: none;
}
.paymentPage .tileSectionImage {
    margin-top: 10px;
    -webkit-filter: invert(22%) sepia(62%) saturate(7187%) hue-rotate(339deg) brightness(95%) contrast(96%);
            filter: invert(22%) sepia(62%) saturate(7187%) hue-rotate(339deg) brightness(95%) contrast(96%);
}
.deliveryLabel {
    flex: 0 0 33.333%;
    max-width: calc(33.333% - 17px);
    height: 55px;
    position: relative;
    background-color: #F7F7F7;
    border-radius: 4px;
    line-height: 24px;
    white-space: nowrap;
    text-indent: 20px;
}
.paymentPage .deliveryLabel.large {
    flex: 0 0 33.333%;
    max-width: calc(33.333% - 17px);
}
.deliveryLabel input {
    -webkit-appearance: none;
            appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border: 1px solid #ED1845;
}
.deliveryLabel input:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    border-radius: 1px;
    background-color: #ED1845;
}
#zasilkovnaWidgetLabel {
    overflow: hidden;
}
@media (max-width: 1200px) {
    .paymentPage .deliveryLabel {
        max-width: 64%;
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
}
@media (max-width: 991px) {
    .paymentPage {
        flex: 0 0 85%;
        max-width: 85%;
    }
    .paymentPage .registerFormButtonSave, .paymentPage .registerFormButtonContinue {
        width: unset;
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
}
@media (max-width: 786px) {
    .paymentPage .desktopSpacer {
        display: none;
    }
    .paymentPage .deliveryLabel {
        max-width: 100%;
    }
    .paymentPage .deliveryLabel.large {
        flex: 0 0 50% !important;
        max-width: calc(50% - 17px) !important;
    }
}
@media (min-width: 526px) and (max-width: 786px) {
    .paymentPage .registerFormButtonSave, .paymentPage .registerFormButtonContinue {
        width: 375px;
        float: left;
    }
}
@media (max-width: 600px) {
    .paymentPage .deliveryLabel.large {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
@media (max-width: 525px) {
    .paymentPage .registerFormButtonSave, .paymentPage .registerFormButtonContinue {
        width: 100%;
    }
}
.summaryPage .cartItemDescText img, .summaryPage .cartItemDescCode img {
    margin-top: -3px;
    margin-right: 3px;
}
.summaryPage .homeTileVerticalDivider {
    display: none;
}
.summaryPage .cartItem {
    padding-bottom: 5px;
}
.summaryPage .registerForm {
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    display: flex;
}
.summaryPage .registerForm input{
    cursor: default;
    color: #808080 !important;
}
#summaryShowCart {
    cursor: pointer;
}
@media (max-width: 993px) {
    .summaryPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
    .summaryPage .registerFormButtonSave, .summaryPage .registerFormButtonContinue {
        width: unset;
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
}
@media (max-width: 930px) {
    .summaryPage .desktopSpacer {
        display: none;
    }
    .summaryPage .deliveryLabel, .summaryPage .deliveryTitle {
        max-width: calc(50% - 17px) !important;
        flex: 0 0 50% !important;
    }
    .desktopOnly {
        display: none;
    }
}
@media (min-width: 787px) {
    .registerFormButtonContinue {
        margin-right: 20px;
    }
}
@media (max-width: 786px) {
    .summaryPage {
        flex: 0 0 90% !important;
        max-width: 90% !important;
    }
}
@media (min-width: 526px) and (max-width: 786px) {
    .summaryPage .registerFormButtonSave, .summaryPage .registerFormButtonContinue {
        width: 327px;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
@media (min-width: 601px) {
    .min-only {
        display: none;
    }
}
@media (max-width: 600px) {
    .max-only {
        display: none;
    }
    .summaryPage .deliveryLabel, .summaryPage .deliveryTitle {
        max-width: 100% !important;
        flex: 0 0 100% !important;
        width: 100%;
    }
}
@media (max-width: 525px) {
    .summaryPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
    .summaryPage .registerFormButtonSave, .summaryPage .registerFormButtonContinue {
        width: 100%;
        float: left;
        margin-top: 0.75rem;
        margin-bottom: 0.75rem;
    }
}
.profilePage .rowSectionTitle img.tileSectionImage {
    width: 49px;
    height: 34px;
    margin-top: 7px;
}
.profilePage .registerForm {
    justify-content: space-between;
}
.profilePage .cartItemControlsPrice {
    white-space: nowrap;
}
.profilePage .cartItem {
    padding-bottom: 4px;
    position: relative;
}
.profilePage .cartItemControlsWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
}
@media (max-width: 1150px) {
    .profilePage .cartCrumbs.Profile {
        max-width: 505px;
        height: 110px;
    }
    .profilePage .cartCrumb {
        margin-top: 20px;
    }
}
@media (max-width: 896px) {
    .profilePage .rowSectionTitle img.tileSectionImage {
        margin-top: 0;
    }
}
@media (min-width: 787px) {
    .registerFormButtonSave {
        margin-right: 20px;
    }
}
@media (max-width: 786px) {
    .profilePage .cartCrumbs.Profile {
        max-width: 250px;
        height: 200px;
    }
}
@media (max-width: 525px) {
    .cartItem {
        width: 230px;
        margin: 0 auto;
    }
    .profilePage .cartItemDescWrapper {
        padding-bottom: 80px;
    }
}
@media (max-width: 400px) {
    .profilePage .tileSection h2 {
        white-space: pre-line;
        width: 200px;
    }
}

.squirrelPage .orderPageButtonWrapper {
    justify-content: flex-end;  
}
.squirrelPage .basicInput {
    height: 57px;
    padding-right: 120px;
    position: relative;
}
.squirrelPage .registerFormButtonContinue, .squirrelPage .registerFormButtonSave {
    min-width: 253px;
}
.squirrelInputWrapper {
    position: relative;
}
.squirrelPage .squirrelInputAccept, .squirrelPage .squirrelInputDeny {
    width: 57px;
    height: 57px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    padding: 10px;
}
.squirrelPage .squirrelInputAccept {
    right: 57px;
}
.squirrelPage .squirrelInputDeny {
    right: 0;
}
@media (min-width: 1051px) {
    .squirrelPage .basicInput {
        flex-grow: 1;
        margin-right: 20px;
    }
}
@media(min-width: 1276px) {
    .squirrelPage .squirrelInputAccept {
        right: 77px;
    }
    .squirrelPage .squirrelInputDeny {
        right: 20px;
    }
}
@media (min-width: 1200px) and (max-width: 1275px) {
    .squirrelInputWrapper {
        flex: 0 0 100%;
        justify-content: flex-end;
        display: flex;
    }
    .squirrelPage .basicInput {
        display: flex;
        flex: 0 0 100%;
        max-width: 530px;
        margin-top: 20px;
        margin-right: 0;
    }
    .squirrelPage .orderPageButtonWrapper {
        flex-wrap: wrap-reverse;
    }
}
@media (min-width: 1051px) and (max-width: 1199px) {
    .squirrelPage .squirrelInputAccept {
        right: 77px;
    }
    .squirrelPage .squirrelInputDeny {
        right: 20px;
    }
}
@media (max-width: 1050px) {
    .squirrelInputWrapper {
        flex: 0 0 100%;
        justify-content: flex-end;
        display: flex;
    }
    .squirrelPage .basicInput {
        display: flex;
        flex: 0 0 100%;
        max-width: 530px;
        margin-top: 20px;
    }
    .squirrelPage .orderPageButtonWrapper {
        flex-wrap: wrap-reverse;
    }
}
@media (max-width:786px) {
    .squirrelInputWrapper {
        margin-bottom: 20px;
        width: 100%;
    }
    .orderPageButtonWrapper a {
        width: 327px;
    }
    .squirrelPage .basicInput {
        max-width: 100%;
    }
}
@media (max-width: 525px) {
    .orderPageButtonWrapper a {
        width: 100%;
    }
    .squirrelPage .registerFormButtonContinue, .squirrelPage .registerFormButtonSave {
        min-width: 242px;
    }
    .squirrelPage .cartItemDescWrapper {
        padding-bottom: 35px;
    }
    .squirrelPage .cartItemControlsClose {
        top: unset;
        bottom: 0;
    }
}
.ordersPage .cartItemControlsClose {
    width: auto;
}
.ordersPage .cartItem {
    padding-bottom: 25px;
}
.ordersPage .cartItemDescHeader {
    font-size: 42px !important;
}
@media (min-width:526px) {
    .ordersPage .cartItemControlsClose {
        top: 40%;
    }
}
