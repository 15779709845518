.newsTile {
    margin-top: 65px !important;
    position: relative;
    padding: 0;
    max-width: 350px;
    width: 350px;
}
.newsText {
    position: absolute;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100px;
    background-color: #ED1845;
    border-radius: 10px;
    color: white;
    z-index: -10;
    padding-top: 8px;
    font-size: 15px;
    font-family: myriad-pro, sans-serif;
}
.popedUp {
    top: -35px;
    transition: 0.8s;
}
.popedDown {
    top: 0;
    transition: 0.8s;
}
.newsText > img {
    margin-right: 5px;
    margin-top: -5px;
    width: 30px;
    height: 30px;
}
.newsInner {
    max-width: 350px;
    position: relative;
    border-radius: 10px;
    color: white;
}
.newsImageWrapper {
    width: 100%;
    padding-top: 100%;
    position: relative;
}
.newsImage {
    width: 100%;
    height: 100%;
    max-width: 350px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 6px 6px 0px rgba(0,0,0,0.15);
    position: absolute;
    top: 0;
    left: 0;
}
.newsBooklet {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.8s;
    border-radius: 175px;
}
.newsBooklet:hover {
    transform: rotate(360deg);
    transition: transform 0.8s;
}
.newsShare {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
}
.newsContent {
    border-radius: 250px;
    transition: 0.6s;
    padding: 50% 0;
}
.newsContent:hover {
    transition: 0.6s;
    transform: rotate(360deg);
}
.newsContentWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (min-width: 641px) and (max-width: 786px) {
    .newsTile {
        max-width: 281px;
    }
    .tileRow .newsTile:nth-child(odd) {
        margin-right: auto !important;
        margin-left: 20px !important;
    }
    .tileRow .newsTile:nth-child(even) {
        margin-left: auto !important;
        margin-right: 20px !important;
    }
}
