@keyframes blink {
    100% {
        opacity: 0;
        width: 190%;
        height: 190%;
    }
    50% {
        opacity: 1;
    }
}
.CDTilePriceEffect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    border: 1px solid white;
    border-radius: 1000px;
    z-index: -12;
    opacity: 1;
    animation: blink 1.5s ease infinite;
}
.CDTilePrice {
    width: 124px;
    height: 124px;
    text-align: center;
    border-radius: 150px;
    background-color: #ED1845;
    border: 1px solid #ED1845;
    position: relative;
    margin: 75px auto;
    transition: 0.6s ease;
    cursor: pointer;
}
.CDTilePrice:hover {
    width: 150px;
    height: 150px;
    background-color: white;
    transition: 0.6s ease;
    margin: 62px auto;
    color: #ED1845;
}
.CDTilePriceInner {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width:100%;
    height: 100%;
    line-height: 5;
    transition: all 0.6s ease;
    color: white;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-size: 17px;
    font-weight: 200;
}
.CDTilePrice:hover .CDTilePriceInner {
    top: -215px;
    transition: all 0.6s ease;
    color: #ED1845;
    font-size: 23px;
}
.CDTilePriceWrapper {
    margin-bottom: -60px;
}
.CDPlayIcon {
    width: 60px;
    height: 60px;
    transition: transform 0.8s;
}
/*.CDPlayIcon:hover {
    transform: rotate(360deg) !important;
    transition: transform 0.8s;
}*/
.CDTileInner .position-relative:hover a .CDPlayIcon {
    transform: rotate(360deg) !important;
    transition: transform 0.8s;
}
.CDPlayIconWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.CDTileInner {
    max-width: 350px;
    width: 350px;
    position: relative;
    border-radius: 10px;
    color: white;
}
.CDTileInner h3 {
    font-size: 76px;
    font-weight: 400;
    font-family: ff-prater-script-web, lobster, sans-serif;
    color: #262626;
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
}
.CDTileInner h4, .CDTileInner h5 {
    font-size: 22px;
    font-weight: 300;
    font-family: myriad-pro, sans-serif;
    color: #A0A0A0;
    text-align: center;
}
.CDTileInner h5 {
    margin-top: 20px;
}
.CDTileShare {
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.CDTileShare > img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 28px;
    cursor: pointer;
}
.CDTileShare > .CDTileShareBody {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    width: 200px;
    height: 120px;
    text-align: left;
    background-color: #ED1845;
    border-radius: 10px;
    visibility: hidden;
    z-index: 1;
}
.CDTileShare:hover > .CDTileShareBody {
    visibility: visible;
    animation: shareMenuRevealAnimation 0.15s reverse;
}
@keyframes shareMenuRevealAnimation {
    0% {
        opacity: 1;
    }
    99.999% {
        visibility: visible;
    }
    100% {
        opacity: 0;
        visibility: hidden;
    }
}
.CDTileShare > .CDTileShareBody .CDTileShareItem {
    width: 100%;
    height: 50px;
    padding: 8px;
    align-self: stretch;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    cursor: pointer;
    line-height: 34px;
}
.CDTileShare > .CDTileShareBody .CDTileShareItem:hover {
    background-color: #b51335;
    transition: background-color 0.15s;
    transition-timing-function: linear;
}
.CDDiscIcon {
    position: absolute;
    right: 48px;
    bottom: 10px;
    width: 28px;
    height: 28px;
}
.commentPopupWapper {
    position: absolute;
    right: 86px;
    bottom: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    border: 1px solid white;
    z-index: 1;
}
.commentPopupWapper::after {
    color: white;
    content: '?';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.commentPopupWapper .commentPopupInner {
    /*display: none;*/
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 5px);
    padding: 10px;
    color: white;
    background: #ED1845;
    width: max-content;
    max-width: 360px;
    /*min-height: 25px;
    aspect-ratio: 3/2;*/
    border-radius: 5px;
    font-size: 22px;
    font-weight: 300;
    font-family: myriad-pro, sans-serif;
    text-align: center;
    visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.6s, opacity 0.6s linear;
}
.commentPopupWapper:hover .commentPopupInner {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.6s linear;
}
@media (max-width: 896px) {
    .CDTileInner, .newsTile {
        max-width: 281px;
    }
    .CDTileInner > h3 {
        font-size: 56px !important;
    }
    .CDTilePriceEffect {
        display: none;
    }
}
@media (max-width: 320px) {
    .CDTileInner, .newsTile {
        max-width: 245px;
    }
    .tileSectionTitleWrapper {
        width: 245px;
    }
}