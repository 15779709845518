nav {
    height: 90px;
    background-color: black;
    transition: background-color 1s;
    transition: margin 0.6s;
    backface-visibility: hidden;
}
@media (min-width: 1117px) {
    nav {
        height: 90px;
    }
    .navbar-brand {
        width: 45px !important;
        height: 45px !important;
    }
    .desktop-navbar {
        display: flex;
    }
    .mobile-navbar {
        display: none;
    }
}
@media (max-width: 1117px) {
    .desktop-navbar {
        display: none;
    }
    .mobile-navbar {
        margin-top: 15px;
        display: flex;
    }
}
nav a {
    color: white;
    font-size: 14px;
    text-transform: uppercase;
}
.navbarBG {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 0px solid black;
    transition: 0.5s;
}
.navbarBG.extend {
    border-bottom: 90px solid black;
    transition: 0.5s;
}
.navbar-items {
    position: absolute;
    right: 24px;
}
.navbar-nav > .nav-item {
    margin-left: 20px;
    height: 40px;
}
#navbarItemCount {
    position: absolute;
    right: 5.1%;
    top: 45%;
    font-size: 6px;
    z-index: 1;
    cursor: pointer;
}
@media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    #navbarItemCount {
        right: 5.2%;
    }
}
@-moz-document url-prefix() {
    #navbarItemCount {
        right: 4.94%;
    }
}
#navbarItemCount:hover + .nav-item {
    filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
}
.nav-item.nav-link.navbarLink.profile {
    display: flex;
    align-items: center;
    filter: brightness(0.8);
    color: white !important;
    max-width: 120px;
    overflow: hidden;
    font-size: 13px;
}
.nav-item.nav-link.navbarLink.profile:hover {
    filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
}
.navbar-nav > .navbar-svg {
    width: 65px;
    cursor: pointer;
}
.navbar-nav > .nav-item:hover {
    filter: invert(28%) sepia(76%) saturate(7495%) hue-rotate(338deg) brightness(95%) contrast(96%);
}
.navbar-nav > .nav-item > img {
    height: 12px;
}
.navbar-nav > .nav-item > svg {
    margin-right: 3px;
    color: white;
}
.navbar-nav > .nav-item:hover > svg {
    margin-right: 3px;
    color: #ED1845;
}
.navbar-nav > a:hover {
    color: #ED1845;
}
.navbar-brand {
    width: 45px;
    height: 45px;
    z-index: 10;
}
.navbar-brand > img {
    margin-top: -10px;
}
@media (min-width: 1117px) {
    .navbar-brand > img {
        margin-top: -2px;
    }
}
.mobile-navbar-images {
    position: absolute;
    right: 10px;
    margin-top: 2px;
}
.mobile-navbar-images > * {
    cursor: pointer;
}
.mobile-navbar-images img {
    width: 21px;
    height: 21px;
    margin-right: 12px;
}
.mobile-cart-count {
    display: inline;
    margin-right: 12px;
    position: relative;
}
.mobile-cart-count p {
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    text-align: center;
    line-height: 10px;
    font-family: alwyn-new-rounded-web, sans-serif;
    font-weight: 300;
    color: white;
}
.mobile-cart-count img {
    margin: 0;
}