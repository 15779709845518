.registerForm {
    margin-bottom: 200px;
}
.registerForm form {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
.registerForm div.registerFormCopy {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}
input.registerInput {
    margin-bottom: 52px;
    max-width: calc(33.33% - 17px);
    width: 33.33%;
    background-color: #F7F7F7;
    height: 55px;
}
.registerformCheckbox {
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #ED1845;
    border-image-source: linear-gradient(45deg, rgba(237,24,69,1) 0%, rgba(237,24,69,1) 85%, rgba(0,0,0,0) 85%, rgba(0,0,0,0) 100%);
    border-image-slice: 1;
    background-color: transparent;
    position: relative;
}
.registerformCheckbox:checked::after {
    content: "";
    border-top: 1px solid #ED1845;
    border-right: 1px solid #ED1845;
    position: absolute;
    width: 20px;
    height: 5px;
    display: block;
    transform: rotate(-225deg);
    margin-top: -4px;
}
.registerFormButtonContinue, .registerFormButtonSave {
    border-radius: 10px;
    border: 1px solid #ED1845;
    text-align: center;
    height: 57px;
    float: right;
    padding-left: 20px;
    padding-right: 20px;
}
.registerFormButtonContinue {
    color: #ED1845;
    background-color: transparent;
}
.registerFormButtonSave {
    background-color: #ED1845;
    color: white;
}
div.inputWrongInfo input.registerInput, div.inputWrongInfo input.basicInput {
    max-width: 100%;
    width: 100%;
    background-color: #ED1845;
    color: white !important;
}
div.inputAnnotated input.registerInput, div.inputAnnotated input.basicInput {
    max-width: 100%;
    width: 100%;
}
div.inputWrongInfo input.registerInput::placeholder, div.inputWrongInfo input.basicInput::placeholder {
    color: white !important;
}
div.inputWrongInfo, div.inputAnnotated {
    position: relative;
    max-width: calc(33.33% - 17px);
    width: 33.33%;
}
div.inputWrongInfo > p {
    color: #ED1845;
}
div.inputWrongInfo > p, div.inputAnnotated > p {
    position: absolute;
    left: 16px;
    top: 70px;
    font: 400 13.3333px Arial;
    z-index: -1;
    animation: slideOutInputWrongInfo 0.5s forwards;
}
@media (max-width: 992px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: 100%;
        width: 100%;
        margin-bottom: 45px;
        height: 55px;
    }
    .registerForm {
        width: 100%;
        margin: 0 auto;
    }
    .registerForm.bottomPart {
        margin-bottom: 300px;
    }
    input.half-input {
        width: calc(50% - 17px);
    }
    .registerFormButtonSave div {
        line-height: 1;
    }
    .registerformCheckbox {
        width: 0 !important;
        height: 0 !important;
        padding-bottom: 11px !important;
        padding-right: 11px !important;
    }
}
@media (max-width: 993px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: calc(33.33% - 17px);
        width: 33%;
    }
    .registerPage {
        flex: 0 0 70% !important;
        max-width: 70% !important;
    }
}
@media (max-width: 930px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: calc(50% - 17px);
        width: 50%;
    }
}
@media (max-width: 786px) {
    .registerFormButtonSave {
        margin-top: 20px;
    }
}
@media (max-width: 600px) {
    input.registerInput, div.inputWrongInfo, div.inputAnnotated {
        max-width: 100%;
        width: 100%;
    }
}
@media (max-width: 525px) {
    .registerPage {
        flex: 0 0 85% !important;
        max-width: 85% !important;
    }
}
@keyframes slideOutInputWrongInfo {
    0% {
        top: 0;
    }
    100% {
        top: 70px;
    }
}