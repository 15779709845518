.DeliveryTerms .tileSectionImage {
    width: 29px !important;
    margin-left: -35px !important;
    margin-top: 10px;
}
.DeliveryTerms > .tileSection > .collapsable {
    padding: 0;
    flex: 0 0 100%;
    max-width: 100%;
}
.DeliveryTerms > .tileSection > .homeTileDivider {
    flex: 0 0 100%;
}
.DeliveryTerms > .tileSection > .rowSectionTitle {
    flex: 0 0 100%;
    max-width: 100%;
}
.DeliveryTerms .homeTileVerticalDivider.visibility-none {
    display: none;
}
@media (max-width: 992px) {
    .DeliveryTerms > .tileSection {
        max-width: 80%;
    }
}
@media (max-width: 640px) {
    .DeliveryTerms {
        max-width: 80%;
    }
    .DeliveryTerms .tileSectionImage {
        width: 22px !important;
    }
    .DeliveryTerms > .tileSection > .collapsable {
        padding: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 550px) {
    .DeliveryTerms > .tileSection > .rowSectionTitle > h2 {
        font-size: 24px;
    }
}
@media (max-width: 475px) {
    .DeliveryTerms > .tileSection > .rowSectionTitle > h2 {
        font-size: 20px;
    }
}
@media (max-width: 410px) {
    .DeliveryTerms > .tileSection > .rowSectionTitle > h2 {
        font-size: 18px;
    }
    .DeliveryTerms .tileSectionImage {
        width: 18px !important;
        margin-top: 7px !important;
        margin-left: -25px !important;
    }
}   