/* cursor: url('path-to-image.png'), auto;	 */
.ControlUtils {
    position: fixed;
    top: -100px;
    cursor: pointer;
}
.ControlImgLeft {
    position: fixed;
    left: 20px;
    margin-top: 20px;
}
.ControlImgLeft img {
    width: 30px;
    height: 33px;
}
.ControlImgRight {
    position: fixed;
    right: 20px;
}
.ControlImgRight img {
    width: 55px;
    height: 46px;
}
.ControlImgRight p {
    width: 100%;
    text-align: center;
    margin-top: -30px;
    color: white;
}
.ControlUtilsUp > .ControlUtils {
    transition: all 0.6s;
    top: -100px;
}
@media (min-width: 1117px) {
    .ControlUtilsDown > .ControlUtils {
        transition: all 0.6s;
        top: 95%;
        transform: translateY(-40px);
    }
}