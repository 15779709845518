.paymentPage .homeTileVerticalDivider {
    display: none;
}
.paymentPage .tileSectionImage {
    margin-top: 10px;
    filter: invert(22%) sepia(62%) saturate(7187%) hue-rotate(339deg) brightness(95%) contrast(96%);
}
.deliveryLabel {
    flex: 0 0 33.333%;
    max-width: calc(33.333% - 17px);
    height: 55px;
    position: relative;
    background-color: #F7F7F7;
    border-radius: 4px;
    line-height: 24px;
    white-space: nowrap;
    text-indent: 20px;
}
.paymentPage .deliveryLabel.large {
    flex: 0 0 33.333%;
    max-width: calc(33.333% - 17px);
}
.deliveryLabel input {
    appearance: none;
    width: 14px;
    height: 14px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    border: 1px solid #ED1845;
}
.deliveryLabel input:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 4px;
    border-radius: 1px;
    background-color: #ED1845;
}
#zasilkovnaWidgetLabel {
    overflow: hidden;
}
@media (max-width: 1200px) {
    .paymentPage .deliveryLabel {
        max-width: 64%;
        flex: 0 0 100%;
        margin-bottom: 20px;
    }
}
@media (max-width: 991px) {
    .paymentPage {
        flex: 0 0 85%;
        max-width: 85%;
    }
    .paymentPage .registerFormButtonSave, .paymentPage .registerFormButtonContinue {
        width: unset;
        margin-top: 0.75rem !important;
        margin-bottom: 0.75rem !important;
    }
}
@media (max-width: 786px) {
    .paymentPage .desktopSpacer {
        display: none;
    }
    .paymentPage .deliveryLabel {
        max-width: 100%;
    }
    .paymentPage .deliveryLabel.large {
        flex: 0 0 50% !important;
        max-width: calc(50% - 17px) !important;
    }
}
@media (min-width: 526px) and (max-width: 786px) {
    .paymentPage .registerFormButtonSave, .paymentPage .registerFormButtonContinue {
        width: 375px;
        float: left;
    }
}
@media (max-width: 600px) {
    .paymentPage .deliveryLabel.large {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}
@media (max-width: 525px) {
    .paymentPage .registerFormButtonSave, .paymentPage .registerFormButtonContinue {
        width: 100%;
    }
}