.squirrelPage .orderPageButtonWrapper {
    justify-content: flex-end;  
}
.squirrelPage .basicInput {
    height: 57px;
    padding-right: 120px;
    position: relative;
}
.squirrelPage .registerFormButtonContinue, .squirrelPage .registerFormButtonSave {
    min-width: 253px;
}
.squirrelInputWrapper {
    position: relative;
}
.squirrelPage .squirrelInputAccept, .squirrelPage .squirrelInputDeny {
    width: 57px;
    height: 57px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    padding: 10px;
}
.squirrelPage .squirrelInputAccept {
    right: 57px;
}
.squirrelPage .squirrelInputDeny {
    right: 0;
}
@media (min-width: 1051px) {
    .squirrelPage .basicInput {
        flex-grow: 1;
        margin-right: 20px;
    }
}
@media(min-width: 1276px) {
    .squirrelPage .squirrelInputAccept {
        right: 77px;
    }
    .squirrelPage .squirrelInputDeny {
        right: 20px;
    }
}
@media (min-width: 1200px) and (max-width: 1275px) {
    .squirrelInputWrapper {
        flex: 0 0 100%;
        justify-content: flex-end;
        display: flex;
    }
    .squirrelPage .basicInput {
        display: flex;
        flex: 0 0 100%;
        max-width: 530px;
        margin-top: 20px;
        margin-right: 0;
    }
    .squirrelPage .orderPageButtonWrapper {
        flex-wrap: wrap-reverse;
    }
}
@media (min-width: 1051px) and (max-width: 1199px) {
    .squirrelPage .squirrelInputAccept {
        right: 77px;
    }
    .squirrelPage .squirrelInputDeny {
        right: 20px;
    }
}
@media (max-width: 1050px) {
    .squirrelInputWrapper {
        flex: 0 0 100%;
        justify-content: flex-end;
        display: flex;
    }
    .squirrelPage .basicInput {
        display: flex;
        flex: 0 0 100%;
        max-width: 530px;
        margin-top: 20px;
    }
    .squirrelPage .orderPageButtonWrapper {
        flex-wrap: wrap-reverse;
    }
}
@media (max-width:786px) {
    .squirrelInputWrapper {
        margin-bottom: 20px;
        width: 100%;
    }
    .orderPageButtonWrapper a {
        width: 327px;
    }
    .squirrelPage .basicInput {
        max-width: 100%;
    }
}
@media (max-width: 525px) {
    .orderPageButtonWrapper a {
        width: 100%;
    }
    .squirrelPage .registerFormButtonContinue, .squirrelPage .registerFormButtonSave {
        min-width: 242px;
    }
    .squirrelPage .cartItemDescWrapper {
        padding-bottom: 35px;
    }
    .squirrelPage .cartItemControlsClose {
        top: unset;
        bottom: 0;
    }
}