.contactPage {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}
.contactPage .homeTileDivider {
    flex: 0 0 100%;
}
.contactPage .tileSectionImage {
    width: 33px !important;
    height: 35px !important;
    margin-left: -42px !important;
    margin-top: 7px;
}
.contactPage .homeTileVerticalDivider {
    display: none;
}
@media (max-width: 550px) {
    .contactPage {
        flex: 0 0 85%;
        max-width: 85%;
    }
}
@media (max-width: 450px) {
    .contactPage {
        flex: 0 0 90%;
        max-width: 90%;
    }
    .contactPage .rowSectionTitle {
        padding-left: 40px;
    }
}