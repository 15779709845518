.footer {
    background-color: black;
    color: white;
    height: 94px;
    white-space: pre;
    font-size: 14px;
    font-family: myriad-pro, sans-serif;
    padding: 36px !important;
    font-weight: 300;
}
.footer a {
    color: #ED1845 !important;
    font-weight: normal;
}
@media (max-width: 992px) {

    .footer * {
        font-size: 11px;
    }

}
@media (min-width: 992px) {

    .footer {
        padding-left: 80px !important;
    }

}