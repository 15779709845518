@keyframes trackPagePulsing{
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.trackPage {
    z-index: 10;
    flex: 0 0 90%;
    max-width: 90%;
}
.trackPlayerWrapper {
    position: relative;
    width: 450px;
    height: 440px;
    margin: 0 auto;
}
.trackPlayerImage {
    width: 450px;
    height: 440px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #333;
    z-index: -1;
}
.audioControls {
    width: 100%;
    height: 100%;
}
.audioTimeline {
    position: absolute;
    bottom: -5px;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    background-color: transparent;
    appearance: none;
}
.audioPlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    z-index: 10;
}
.audioPrevious {
    width: 72px;
    height: 72px;
    margin-top: 20px;
    cursor: pointer;
}
.audioToggleWrapper {
    width: 112px;
    height: 112px;
    border: 1px solid white;
    border-radius: 70px;
}
.audioToggle {
    cursor: pointer;
    width: 112px;
    height: 112px;
}
.audioNext {
    width: 72px;
    height: 72px;
    margin-top: 20px;
    cursor: pointer;
}
.trackPagePulsing {
    animation: trackPagePulsing 1.5s infinite;
}


#audioTimeLineGrey {
    position: absolute;
    bottom: -15px;
    left: 0;
    height: 2px;
    background-color: rgba(0,0,0,.3);
    z-index: 90;
}
#audioTimeLineRed {
    position: absolute;
    bottom: -15px;
    left: 0;
    height: 2px;
    background-color: #ED1845;
    z-index: 100;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.audioVolumeWrapper {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 215px;
    height: 28px;
    cursor: pointer;
}
#audioVolumeImage {
    width: 39px;
    height: 28px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/assets/images/speakeron.svg");
}
#audioVolumeImage:hover {
    background-image: url("/assets/images/speakeroff.svg");
}
#audioVolumeImage.muted {
    background-image: url("/assets/images/speakeroff.svg");
}
#audioVolumeImage.muted:hover {
    background-image: url("/assets/images/speakeron.svg");
}
.audioVolumeWrapper:hover .audioVolumeSliderWrapper {
    display: block;
}
.audioVolumeSliderWrapper {
    position: absolute;
    top: 0;
    left: 39px;
    width: calc(100% - 39px);
    display: none;
    height: 100%;
}
#audioVolume::-webkit-slider-runnable-track {
    height: 4px !important;
    border-radius: 0;
}
#audioVolume::-moz-range-track {
    height: 4px !important;
    border-radius: 0;
}
#audioVolume::-ms-track {
    height: 4px !important;
    border-radius: 0;
}
#audioVolume {
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
#audioVolume::-webkit-slider-runnable-track {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: 0;
}
#audioVolumeRed {
    position: absolute;
    top: 50%;
    left: 0;
    height: 4px !important;
    background-color: #ED1845;
    z-index: 100;
    transform: translateY(-50%);
}
/*@-moz-document url-prefix() {
    #audioVolumeRed {
        bottom: 9px;
    }
}*/
#audioVolume::-webkit-slider-thumb {
    visibility: hidden;
}
#audioVolume::-moz-range-thumb {
    visibility: hidden;
}
#audioVolume::-ms-thumb {
    visibility: hidden;
}

@-moz-document url-prefix() {
    .audioTimeline {
        bottom: -8px;
    }
}


input[type=range] {
    height: 12px;
    appearance: none;
    width: 100%;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    transition: 0.2s;
    background: white;
    border-radius: 5px;
    margin-top: 5px;
}
input[type=range]::-webkit-slider-thumb {
    height: 12px;
    width: 2px;
    margin-top: -5px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 110;
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    transition: 0.2s;
    background: white;
    border-radius: 5px;
    margin-top: 5px;
}
input[type=range]::-moz-range-thumb {
    height: 12px;
    width: 2px;
    margin-top: -5px;
    background: #FFFFFF;
    cursor: pointer;
    border: none;
    border-radius: 0;
    appearance: none;
    z-index: 110;
}
input[type=range]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    transition: 0.2s;
    background: white;
    border-radius: 5px;
    margin-top: 5px;
}
input[type=range]::-ms-fill-lower {
    /*background: #3071A9;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000; */
}
input[type=range]::-ms-fill-upper {
    /*background: #3071A9;
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000; */
}
input[type=range]::-ms-thumb {
    height: 12px;
    width: 2px;
    margin-top: -5px;
    background: #FFFFFF;
    cursor: pointer;
    -webkit-appearance: none;
    z-index: 110;
}




/*.audioTimeLine::-webkit-slider-thumb, .audioTimeLine::-moz-range-thumb, .audioTimeLine::-ms-thumb {
    height: 10px;
    width: 3px;
    background-color: white;
}
.audioTimeLine::-webkit-slider-runnable-track, .audioTimeLine::-moz-range-track, .audioTimeLine::-ms-track  {
    height: 3px;
    background-color: white;
}*/
.audioTime {
    position: absolute;
    bottom: -42px;
    right: 0;
    color: white;
    font-size: 12px;
    line-height: 14px;
    font-family: futura-pt, sans-serif;
    font-weight: 300;
}
.trackPlayerAudio {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 11;
}
.trackInfo {
    color: white;
    text-align: center;
    margin-left: auto;
}
.trackInfo.mobile {
    color: black;
}
.trackInfo h2 {
    font-family: ff-prater-script-web, sans-serif;
    font-weight: 400;
    font-size: 68px;
    line-height: 82px;
    text-transform: lowercase;
}
.trackInfo h3 {
    color: #A0A0A0;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 26px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    margin-top: 50px;
}
.trackInfo h4 {
    color: #ED1845;
    font-size: 22px;
    line-height: 26px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.songList {
    margin-bottom: 100px;
    width: 50%;
    margin-left: 10vw;
}
.songListTitle {
    font-size: 35px;
    height: 45px;
    line-height: 42px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.songList p {
    text-align: left;
    font-size: 18px;
    line-height: 36px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    color: #A0A0A0;
    margin-bottom: 0;
    padding-left: 5px;
    white-space: nowrap;
}
.songListImage {
    width: 49px;
    height: 39px;
    margin-left: -55px;
    float: left;
}
.songListItem {
    cursor: pointer;
}
.songListItem.playing::before {
    animation: trackPagePulsing 1.5s infinite;
}
.songListItem.playing, .songListItem.paused {
    position: relative;
}
.songListItem.playing::before, .songListItem.paused::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    filter: brightness(60%);
    background: url("/assets/images/musicicons_play-white.png") no-repeat;
    background-size: 200% 200%;
    background-position: center;
}
.songListItem.paused::before {
    background: url("/assets/images/musicicons_pause-white.png") no-repeat;
    background-size: 200% 200%;
    background-position: center;
}
.relatedTitles {
    margin: 0 auto;
    padding: 0;
    width: 380px;
    min-width: 380px;
    margin-bottom: 50px;
}
.relatedTitle {
    width: 165px;
    height: 165px;
    padding: 0px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 40px;
    overflow: hidden;
    flex: none;
    box-shadow: 6px 6px 0px rgba(0,0,0,0.15);
    /* width: calc(50% - 20px); */
    display: block;
    float: left;
}
.relatedTitle:nth-child(odd) {
    margin-left: 0;
    margin-right: 20px;
}
.relatedTitle:nth-child(even) {
    margin-right: 0;
    margin-left: 20px;
}
.relatedTitle img {
    width: 165px;
    height: 165px;
    object-fit: cover;
}
.trackPageSongList {
    flex: 0 0 55%;
    max-width: 55%;
}
.trackPageRelatedList {
    flex: 0 0 45%;
    max-width: 45%;
}
.relatedTitleWrapper {
    display: flex;
}
.trackPage .commentPopupWapper {
    position: relative !important;
    margin: 0 auto;
    margin-bottom: 90px;
    right: unset;
    bottom: unset;
    width: 50px;
    height: 50px;
    border: 1px solid #A0A0A0;
    z-index: 1;
}
.trackPage .commentPopupWapper::after {
    color: #A0A0A0;
    content: '?';
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 23px;
    transform: translate(-50%, -50%);
}
.trackPage .commentPopupWapper .commentPopupInner {
    /*display: none;*/
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 5px);
    padding: 10px;
    border-radius: 5px;
}
.trackPage .commentPopupWapper:hover .commentPopupInner {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.6s linear;
}

@media (max-width: 992px) {
    .trackPlayerWrapper {
        max-width: 450px;
    }
    .trackPage {
        flex: 0 0 95%;
        max-width: 95%;
    }
    .p-sm-0 {
        padding: 0;
    }
    .trackPlayerImage {
        width: 100%;
        height: auto;
    }
    .trackInfo.mobile {
        margin: 0 auto;
    }
    .songList {
        margin: 0;
        margin-left: calc(50% - 82.5px);
        width: 165px;
    }
    .relatedTitle {
        width: 165px;
        height: 165px;
        margin: 0 auto !important;
        margin-bottom: 50px !important;
    }
}

@media (max-width: 786px) {
    .trackPageSectionDivider {
        border-bottom: 1px solid #A0A0A0;
        width: 50%;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 50px;
        display: block;
    }
    .trackPlayerBlackSection {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .trackPlayerWrapper {
        width: 450px;
        height: unset;
        padding-top: 450px;
        margin: 0 auto;
    }
    .trackInfo {
        display: none;
    }
    .trackInfo.mobile {
        display: block;
    }
    .relatedTitlesList {
        width: 100%;
    }
    .trackPageSongList {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .trackPageRelatedList {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .relatedTitleWrapper {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .songListTitleWrapper {
        margin: 0 auto;
    }
    .trackPage .commentPopupWapper {
        border: 1px solid #A0A0A0;
    }
    .trackPage .commentPopupWapper::after {
        color: #A0A0A0;
    }
}
@media (max-width: 500px) {
    .trackPlayerWrapper {
        width: 320px;
        padding-top: 320px;
    }
    .relatedTitles {
        min-width: 320px;
        width: 320px;
        margin: 0 auto;
    }
    .trackPage {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media (max-width: 340px) {
    .trackPlayerWrapper {
        width: 310px;
        padding-top: 310px;
    }
}
@media (min-width: 787px) {
    .trackPlayerBlackSection {
        flex: 0 0 50%;
        max-width: 450px;
    }
    .trackInfo {
        display: block;
    }
    .trackInfo.mobile {
        display: none;
    }
    .trackPageSectionDivider {
        display: none;
    }
    .relatedTitleWrapper {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width:901px) and (max-width:992px) {
    .trackInfo {
        max-width: calc(100% - 450px);
    }
}

@media (min-width: 787px) and (max-width:900px) {
    .trackPage {
        margin-left: 0;
        margin-right: 0;
    }
    .trackInfo {
        max-width: calc(100% - 400px);
    }
    .trackPlayerBlackSection {
        max-width: 400px;
    }
    .trackPlayerWrapper {
        max-width: 400px;
        max-height: 390px;
        margin-top: 25px;
    }
    .trackPageSongList {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .trackPageRelatedList {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
@media (min-width: 993px) {
    .trackPlayerWrapper {
        width: 450px;
        height: 440px;
    }
    .trackPlayerImage {
        width: 450px;
        height: 440px;
    }
    .trackInfo .CDTilePriceWrapper {
        margin-top: -50px;
    }
    .trackInfo {
        max-width: 500px;
        margin-right: 0;
    }
    .trackPlayerBlackSection {
        margin-left: auto;
        margin-right: auto;
    }
}
@media (min-width: 1050px) {
    .trackInfo {
        margin-right: 30px;
    }
}
@media (min-width: 1100px) {
    .trackInfo {
        margin-right: 50px;
    }
}
@media (min-width: 1200px) {
    .trackInfo {
        margin-right: 100px;
    }
    .trackPage {
        flex: 0 0 82%;
        max-width: 82%;
    }
}
@media (min-width: 1350px) {
    .trackInfo {
        max-width: 750px;
        min-width: 200px;
    }
}