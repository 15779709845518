/*@import url('https://use.typekit.net/fgo1mak.css');*/
@import url('https://use.typekit.net/ywc8whz.css');

*:focus {
    outline: none !important;
}
body > iframe {
    display: none !important;/*some random iframe element keeps popping up, maybe something from react-router or bundle.js or some other package idk*/
}
main iframe {
    display: block !important;
}
body {
    width: 100%;
    background-color: #E8E8E8 !important;
    z-index: -100;
    overflow-x: hidden;
}
h1, h2, h3, h4, h5, p {
    font-feature-settings: 'liga';
}
nav {
    width: 100vw;
}
main {
    transition: margin 0.6s;
    margin: 0;
}
a {
    color: unset !important;
}
a:hover {
    text-decoration: none !important;
}
.basicInput {
    padding: 16px;
    border-radius: 4px;
    border: none;
    font: 400 13.3333px Arial;
    height: 45px;
}
.standardFont {
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
}
.container-fluid {
    padding: 0 !important;
    width: 100%;
}
.hiddenButton {
    border: none;
    background-color: transparent;
}
.hiddenButton:focus {
    outline: none;
}
.redLink {
    color: #ED1845 !important;
}
.red {
    color: #ED1845 !important;
}
.redLink:hover {
    color: #ED1845 !important;
    text-decoration: underline;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
.addToCartWrapper {
    width: 135px;
    height: 135px;
    border-radius: 100px;
    background-color: #ED1845;
    position: fixed;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.8s;
    opacity: 0.9;
    z-index: 1031;
    cursor: pointer;
}
.addToCartWrapperDown {
    top: 10%;
}
.addToCartWrapper img {
    width: 50px;
    height: auto;
    margin: 42px;
    margin-top: 46px;
}
.color-gray {
    color: #A0A0A0 !important;
}
.placeholder-black::placeholder {
    color: #2F2F2F !important;
}
.color-black {
    color: #2F2F2F !important;
}
.color-red {
    color: #ED1845 !important;
}
.color-white {
    color: white !important;
}
.min-h-100 {
    min-height: 100vh;
}
.vertical-center {
    display: flex;
    align-items: center;
  }
@media (max-width: 993px) {
    .w-95 {
        width: 95vw;
        margin: 0 auto;
    }
    .max-h-100 {
        max-height: 100vh;
        height: 100vh;
    }
}
@media (max-width: 640px) {
    .mobile-block {
        display: block;
    }
    .mobile-flex {
        display: flex;
    }
}
@media (min-width: 640px) {
    .mobile-block, .mobile-flex {
        display: none;
    }
}