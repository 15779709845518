.fastShop {
    min-height: 100vh;
}
.fastShop .homeTileVerticalDivider {
    display: none;
}
.fastShopTableWrapper {
    max-width: 96vw;
    overflow-x: scroll;
    margin: 0 auto;
}
@media (min-width: 992px) {
    .fastShopTableWrapper {
        overflow-x: hidden;
    }
}
.fastShopTable {
    min-width: 700px;
}
.fastShopTable table {
    width: 100%;
    margin-bottom: 400px;
}
.fastShopTable table td:first-child {
    border-left: 1px dotted #A0A0A0;
}
.GridText {
    position: relative;
    font-style: italic;
    font-size: 17px;
    font-weight: 300;
    color: black;
}
.GridTextWrapper {
    position: relative;
}
.GridTextCross {
    position: absolute;
    top: 0;
    right: 0;
}
.GridTextCross:before, .GridTextCross:after {
    position: absolute;
    right: 25px;
    top: 14px;
    content: ' ';
    height: 16px;
    width: 2px;
    background-color: #333;
    border-radius: 1px;
    display: none;
}
.GridTextCross.show::before, .GridTextCross.show::after {
    display: inline;
}
.GridTextCross:before {
    transform: rotate(45deg);
}
.GridTextCross:after {
    transform: rotate(-45deg);
}
.GridText::placeholder {
    color: #A0A0A0;
}
.fastShopTable th {
    height: 76px;
    color: white;
    background-color: #ED1845;
    border: 1px dotted white;
    text-transform: uppercase;
    /*border: 1px dotted #ED1845;*/
    border-spacing: 0px;
    text-align: center;
    transition: all 0.25s;
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 17px;
    line-height: 17px;
    font-family: myriad-pro, sans-serif;
    font-weight: bold;
    cursor: pointer;
    margin-left: -1px;
    display:flex !important;
    justify-content:center;
    align-items:center;
}
.fastShopTable th::before {
    transition: all 0.25s;
    width: 7px;
    height: 7px;
    border-left: 2px solid white;
    border-top: 2px solid white;
    transform: rotate(45deg);
    content: "";
    /* display: none; */
    opacity: 0;
    position: absolute;
    right: 9px;
    top: calc(50% - 2px);
    transform: scale(0);
}
.fastShopTable table.reverse th::before {
    transform: rotate(225deg) !important;
    top: calc(50% - 5px);
}
.fastShopTable table tr {
    display: flex;
    flex: 1;
}
.fastShopTable table tr td {
    display:flex;
    align-items:center;
    overflow: hidden;
}
.fastShopTable table[class^="orderBy"] th::before {
    transform: scale(1) rotate(45deg);
}
.fastShopTable table.orderBy1 th:nth-child(1)::before {
    /*display: block;*/
    opacity: 1;
    /* scale: 1; */
}
.fastShopTable table.orderBy2 th:nth-child(2)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy3 th:nth-child(3)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy4 th:nth-child(4)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy5 th:nth-child(5)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy6 th:nth-child(6)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy7 th:nth-child(7)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable table.orderBy8 th:nth-child(8)::before {
    /*display: block;*/
    opacity: 1;
}
.fastShopTable th:nth-child(1) {
    /*border-top-left-radius: 10px;*/
    border-left: 1px dotted white;
    margin-left: 0;
}
.fastShopTable th:nth-child(8) {
    border-top-right-radius: 10px;
}
.fastShopTable td:nth-child(1) {
    border-left: 1px dotted #A0A0A0;
    margin-left: 0;
}
.fastShopTable th:nth-child(1), .fastShopTable td:nth-child(1) {
    width: 13%;
}
.fastShopTable th:nth-child(2), .fastShopTable td:nth-child(2) {
    width: 20%;
}
.fastShopTable th:nth-child(3), .fastShopTable td:nth-child(3) {
    width: 10%;
}
.fastShopTable th:nth-child(4), .fastShopTable td:nth-child(4) {
    width: 9%;
}
.fastShopTable th:nth-child(5), .fastShopTable td:nth-child(5) {
    width: 9%;
    min-width: 64px;
}
.fastShopTable th:nth-child(6), .fastShopTable td:nth-child(6) {
    width: 19%;
    min-width: 134px;
}
.fastShopTable th:nth-child(7), .fastShopTable td:nth-child(7) {
    width: 15%;
}
.fastShopTable th:nth-child(8), .fastShopTable td:nth-child(8) {
    width: 10%;
    min-width: 70px;
}
.fastShopTable table.orderBy1 th:nth-child(1), .fastShopTable table.orderBy1 td:nth-child(1) {
    width: calc(13% + 50px);
}
.fastShopTable table.orderBy2 th:nth-child(2), .fastShopTable table.orderBy2 td:nth-child(2) {
    width: calc(20% + 50px);
}
.fastShopTable table.orderBy3 th:nth-child(3), .fastShopTable table.orderBy3 td:nth-child(3) {
    width: calc(10% + 50px);
}
.fastShopTable table.orderBy4 th:nth-child(4), .fastShopTable table.orderBy4 td:nth-child(4) {
    width: calc(9% + 50px);
}
.fastShopTable table.orderBy5 th:nth-child(5), .fastShopTable table.orderBy5 td:nth-child(5) {
    width: calc(9% + 50px);
}
.fastShopTable table.orderBy5 th:nth-child(5) {
    padding-right: 25px;
}
.fastShopTable table.orderBy6 th:nth-child(6), .fastShopTable table.orderBy6 td:nth-child(6) {
    width: calc(19% + 50px);
}
.fastShopTable table.orderBy7 th:nth-child(7), .fastShopTable table.orderBy7 td:nth-child(7) {
    width: calc(15% + 50px);
}
.fastShopTable table.orderBy8 th:nth-child(8), .fastShopTable table.orderBy8 td:nth-child(8) {
    width: calc(10% + 50px);
}
.fastShopTable td {
    border-right: 1px dotted #A0A0A0;
    border-bottom: 1px dotted #A0A0A0;
    height: 70px;
    transition: all 0.25s;
    background-color: white;
    padding-left: 10px;
    color: #A0A0A0;
    border: 1px dotted #A0A0A0;
    margin-top: -1px;
    margin-left: -1px;
}
.fastShopTable td:nth-child(5) {
    cursor: pointer;
    color: #ED1845;
}
.fastTableControls {
    height: 46px;
    margin-bottom: 1px;
}
.fastTableControls > * {
    float: left;
}
.fastTableControls > .GridButton {
    width: 50px;
    height: 46px;
    border: none;
    color: white;
}
.fastTablePagination > .fastPagination {
    width: 50px;
    height: 46px;
    border: none;
    color: white;
    float: left;
    background-color: #ED1845;
    border-radius: 3px;
    margin-right: 3px;
    padding: 10px;
    padding-left: 20px;
}
.fastTableControls > .GridButton > a {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    line-height: 46px;
    display: block;

}
.fastTableControls > button:nth-child(1) {
    border-top-left-radius: 10px;
    background-color: #ED1845;
    margin-right: 3px;
}
.fastTableControls > button:nth-child(2),
.fastTableControls > div:nth-child(3),
.fastTableControls > button:nth-child(4),
.fastTableControls > button:nth-child(5) {
    background-color: #ED1845;
    margin-right: 3px;
}
.fastTableControls > button:nth-child(5) {
    border-top-right-radius: 5px;
}
.fastTableControls input.GridText {
    width: 362px;
    height: 46px;
    padding: 0 15px;
    margin: 0;
    border: none;
    border-radius: 5px;
}
.GridButton {
    transition: 0.2s;
}
.GridButton:hover {
    background-color: #474747 !important;
    transition: 0.2s;
}
.GridSelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 44px;
    height: 46px;
    text-align: center;
    background-color: #ED1845;
    color: white;
    font-size: 17px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    font-style: italic;
    border: none;
    line-height: 46px;
    position: relative;
    cursor: pointer;
}
.GridSelect.show::before {
    content: "";
    width: 44px;
    height: 184px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.09);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 9;
}
.GridSelect > .GridOption {
    display: none;
    border-left: transparent;
    border-right: transparent;
    border-width: 0px;
    border-style: solid;
    cursor: pointer;
}
.GridSelect > .GridOption:hover {
    color: rgb(244, 116, 143);
    transition: 0.2s;    
}
.GridSelect > .GridOption.selected {
    color: rgb(244, 116, 143);
}
.GridSelect.show > .GridOption:nth-child(1) {
    margin-top: -46px;
}
.GridSelect.show > .GridOption:nth-child(2) {
    border-top: 1px solid #757575;
}
.GridSelect.show > .GridOption:nth-child(3) {
    border-top: 1px solid #757575;
}
.GridSelect.show > .GridOption:nth-child(4) {
    border-top: 1px solid #757575;
}
.GridSelect.show > .GridOption {
    width: 44px;
    height: 46px;
    display: block;
    float: left;
    background-color: #ED1845;
    line-height: 46px;
    text-align: center;
    font-size: 17px;
    font-family: myriad-pro, sans-serif;
    font-weight: 300;
    font-style: italic;
    position: relative;
    z-index: 10;
}
.fastShopTable table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
.fastShopTable table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
.fastShopTable table.orderBy1 tbody tr td:nth-child(1) {
    background-color: rgba(214,214,214, 0.77);
    color: black;

}
.fastShopTable table.orderBy2 tbody tr td:nth-child(2) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy3 tbody tr td:nth-child(3) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy4 tbody tr td:nth-child(4) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy5 tbody tr td:nth-child(5) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy6 tbody tr td:nth-child(6) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy7 tbody tr td:nth-child(7) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}
.fastShopTable table.orderBy8 tbody tr td:nth-child(8) {
    background-color: rgba(214,214,214, 0.77);
    color: black;
}